import React from 'react';
import cn from 'classnames';

import { useResize } from '../../utils/hooks';

import { Review1 } from './types';
import style from './ReviewScroll1.module.css';
import RoundButton from './../UIkit/RoundButton';

type Props = {
  current: number;
  setCurrentReview: React.Dispatch<React.SetStateAction<number>>;
  reviews: Review1[];
};

function ReviewScroll1({ current, reviews, setCurrentReview }: Props): JSX.Element {
  // console.log(index === current);
  const { width, isScreen900 } = useResize();
  // if (isScreen900)
  const totalPages = isScreen900 ? Math.ceil(reviews.length / 2) : reviews.length;

  // const currentPage = Math.floor(current / 2) + 1;
  // const onClickPrevHandler = () => {
  //   setCurrentReview(prev => (prev === 0 ? reviews.length - 1 : prev - 1));
  // };

  // const onClickNextHandler = () => {
  //   setCurrentReview(prev => (prev === reviews.length - 1 ? 0 : prev + 1));
  // };
  // const [currentPage, setCurrentPage] = React.useState(2)
  // React.useEffect(() => {
  //   if (current == 0 && next == 1) setCurrentPage(1);
  //   if (current == 2 && next == 3) setCurrentPage(2);
  //   if (current == 4 && next == 0) setCurrentPage(3);
  // }, [current])
  const onClickPrevHandler = () => {
    if (isScreen900) {
      setCurrentReview(prev => (prev === 1 ? reviews.length - 2 : prev - 1));
    } else {
      setCurrentReview(prev => (prev === 1 ? reviews.length : prev - 1));
    }
    // setCurrentReview((prev) => (prev - 2));
  };
  const onClickNextHandler = () => {
    if (isScreen900) {
      setCurrentReview(prev => (prev === reviews.length - 2 ? 1 : prev + 1));
    } else {
      setCurrentReview(prev => (prev === reviews.length ? 1 : prev + 1));
    }
    // setCurrentReview((prev) => (prev + 2));
  };

  // const displayCurrent = isScreen900 ? current : current + 1;
  return (
    <div className={style.container}>
      <div className={style.steps}>
        <div className={style.text}>
          <div className={style.main}>0{current}</div>
          <div className={style.greytext}> \ 0{totalPages}</div>
        </div>
        <div className={style.line}>
          {new Array(totalPages).fill(0).map((_, index) => {
            const isActive = index + 1 === current;
            return <div key={`ReviewScroll1__${index}`} className={cn(style.item, isActive && style.current)} />;
          })}
        </div>
      </div>
      <div className={style.btns}>
        <RoundButton pos={'left'} onClick={onClickPrevHandler} />
        <RoundButton pos={'right'} onClick={onClickNextHandler} />
      </div>
    </div>
  );
}

export default ReviewScroll1;
