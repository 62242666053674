import React, { useState } from 'react';
import cn from 'classnames';
import { FieldProps } from 'formik';

import { InputIconMan, InputIconAddress, InputIconEmail, InputIconEye, InputIconOpenEye, InputIconCard } from '../SVG';

import style from './Input.module.css';

type Props = {
  field: FieldProps['field'];
  form: FieldProps['form'];
  className?: string;
  placeholder?: string;
  icon?: string;
  props: object;
  showPassword: () => void;
  small?: boolean;
  fullWidth?: boolean;
  type: string;
};

const Input = ({
  field,
  form,
  className,
  placeholder,
  icon,
  showPassword,
  small,
  fullWidth,
  type = 'text',
}: Props): JSX.Element => {
  const [isFocus, setFocus] = useState(false);

  const touched = form ? form.touched : {};
  const errors = form ? form.errors : {};
  const { name } = field;
  const isError = !!(touched[name] && errors[name]);
  const isEmpty = field.value === '' || field.value === undefined;
  const isPassword = icon === 'eye' || icon === 'openEye';

  function selectIcon(): JSX.Element | undefined {
    switch (icon) {
      case 'man':
        return <InputIconMan green={isFocus} white={isError} />;

      case 'address':
        return <InputIconAddress green={isFocus} white={isError} />;

      case 'email':
        return <InputIconEmail green={isFocus} white={isError} />;

      case 'eye':
        return <InputIconEye green={isFocus} white={isError} />;

      case 'openEye':
        return <InputIconOpenEye green={isFocus} white={isError} />;

      case 'card':
        return <InputIconCard green={isFocus} white={isError} />;
    }
  }

  function returnPlaceholder(): JSX.Element | undefined {
    if (!isEmpty || isFocus) {
      return <p className={style.placeholder}>{placeholder}</p>;
    }
  }

  function handleClick(): void {
    if (isPassword) {
      showPassword();
    }
  }

  function handleFocus(): void {
    setFocus(true);
  }

  function handleBlur(): void {
    setFocus(false);
  }

  return (
    <div className={style.wrapper}>
      <div className={cn(style.container, className, small && style.smallContainer, fullWidth && style.fullWidth)}>
        {returnPlaceholder()}
        <input
          {...field}
          type={type}
          className={cn(
            style.input,
            className,
            isError && style.incorrectInput,
            !isEmpty && style.filledInput,
            small && style.smallInput,
          )}
          placeholder={placeholder || ''}
          aria-label={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {icon && (
          <div
            onClick={handleClick}
            className={cn(
              style.icon,
              isFocus && style.focusedIcon,
              isError && style.incorrectIcon,
              isPassword && style.passwordIcon,
            )}
          >
            {selectIcon()}
          </div>
        )}
      </div>
      {isError && (
        <div className={style.errorContainer}>
          <p>{errors[name]}</p>
        </div>
      )}
    </div>
  );
};

export default Input;
