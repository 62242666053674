import React from 'react';
import cn from 'classnames';

import ReviewStyle from '../SVG/ReviewStyle.svg';
import User from '../SVG/User.svg';
import NavigationButton from '../UIkit/NavigationButton';

import { Review1 } from './types';
import style from './ReviewItem1.module.css';

type ReviewItem1 = {
  key?: number;
  review: Review1;
  className: string;
};

function ReviewItem1({ review, className }: ReviewItem1): JSX.Element {
  return (
    <div className={cn(className, style.container)}>
      <img className={style.ReviewStyle} src={ReviewStyle} alt="ReviewStyle" />
      <div className={style.header}>
        <div>
          <p className={style.name}>{review.name}</p>
          <p className={style.company}>{review.company}</p>
        </div>
        <div className={style.position}>
          <img src={User} alt="User" />
          <p className={style.position__text}>{review.position}</p>
        </div>
      </div>
      <p className={style.review}>{review.review}</p>
      {/* <NavigationButton title='Читать полностью ' className={style.NavigationButton} onClick={() => window.open(review.pdfFile, '_blank')}/> */}
    </div>
  );
}

export default ReviewItem1;
