import React, { useState, useEffect, useRef } from 'react';
import { animateScroll } from 'react-scroll';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
// import LoginButton from '../Buttons/LoginButton';
import { scroller } from 'react-scroll';

import { StoreContext } from '../../store';
import Tag from '../UIkit/Tag';
import DropdownMenu from '../UIkit/DropdownMenu';
import { DigitalPlatformSections1 } from '../DigitalPlatform1/constants';

import style from './Header2.module.css';
import HeaderItem2 from './HeaderItem2';

function Header2(): JSX.Element {
  const [isOpen, setOpen] = useState(false);
  const [smallHeader, setSmallHeader] = useState(false);

  const listener = (): void => {
    console.log('listener');

    if (window.scrollY > 20) {
      setSmallHeader(true);
    } else {
      setSmallHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return (): void => {
      window.removeEventListener('scroll', listener);
    };
  });

  function scrollToTop(): void {
    // animateScroll.scrollToTop();

    scroller.scrollTo('main1', {
      duration: 800,
      smooth: true,
      offset: 0,
    });
  }

  function hideMenu(): void {
    setOpen(false);
  }

  const { contactUs } = React.useContext(StoreContext);
  // React.useContext(StoreContext);

  function handleToggleClick(): void {
    setOpen(!isOpen);
  }

  const hostname = window.location.hostname;
  // const isBY = hostname.endsWith('.by');
  // const menuItems = [
  //   { to: 'advantages', label: 'Преимущества' },
  //   { to: 'product', label: 'Продукт' },
  //   { to: 'clients', label: 'Клиенты', isHidden: isBY },
  //   { to: 'calculator', label: 'Калькулятор', isHidden: isBY },
  //   { to: 'equipments', label: 'Оборудование' },
  //   { to: 'review', label: 'Отзывы', isHidden: isBY },
  // ];
  const menuItems = [
    { to: 'platform1', label: 'Платформа', id: 1 },
    {
      id: 0,
      label: 'Продукты',
      to: 'product',
      dropdownItems: DigitalPlatformSections1.map(section => ({
        label: section.Name,
        to: `product/${section.EnName}`,
        id: section.Id,
      })),
    },
    { to: 'company', label: 'О компании', id: 3 },
    { to: 'clients1', label: 'Клиенты', id: 7 },
    { to: 'review1', label: 'Отзывы', id: 9 },
    { to: 'blog1', label: 'Блог', id: 10 },
  ];

  const checkScrollRef = useRef<number>();
  const checkScrollPosition = () => {
    const scrollContent = document.querySelector('.scroll-content') as HTMLElement;
    if (scrollContent) {
      const transform = window.getComputedStyle(scrollContent).transform;
      const matrix = transform.match(/^matrix3d\((.+)\)$/);
      let y = 0;
      if (matrix) {
        const values = matrix[1].split(', ');
        y = parseFloat(values[13]);
      } else {
        const matrix2d = transform.match(/^matrix\((.+)\)$/);
        if (matrix2d) {
          const values = matrix2d[1].split(', ');
          y = parseFloat(values[5]);
        }
      }
      setSmallHeader(y !== 0);
    }
    checkScrollRef.current = requestAnimationFrame(checkScrollPosition);
  };
  useEffect(() => {
    checkScrollRef.current = requestAnimationFrame(checkScrollPosition);
    return () => {
      if (checkScrollRef.current) {
        cancelAnimationFrame(checkScrollRef.current);
      }
    };
  }, []);

  return (
    <header className={cn(style.header, smallHeader && style.small)}>
      <nav className={style.nav}>
        <button className={style.logo} onClick={scrollToTop} aria-label="logo" />
        <ul className={style.ulmain}>
          <OutsideClickHandler onOutsideClick={hideMenu}>
            <div className={cn(style.itemsContainer, isOpen && style.mobileMenu)}>
              {menuItems.map(item => {
                // if (item.isHidden) return;
                if (item.dropdownItems) {
                  return (
                    <DropdownMenu
                      key={item.to}
                      title={item.label}
                      items={item.dropdownItems}
                      className={cn(style.item, isOpen && style.itemAppear)}
                    />
                  );
                }
                return (
                  <HeaderItem2
                    key={item.to}
                    onClick={hideMenu}
                    id={item.id}
                    to={item.to}
                    className={cn(style.item, isOpen && style.itemAppear)}
                  >
                    {item.label}
                  </HeaderItem2>
                );
              })}
              {/* {DigitalPlatformSections1.map(section => (
                <HeaderItem2
                  key={section.Id}
                  onClick={hideMenu}
                  id={section.Id}
                  to={section.EnName}
                  className={style.dropdownItem}
                >
                  {section.Name}
                </HeaderItem2>
              ))} */}
            </div>
          </OutsideClickHandler>
        </ul>
        <div className={style.contacts}>
          <div className={style.phone}>
            <a href="tel:+7(985)305-05-41">+7 (985) 305-05-41</a>
          </div>
          {/* <Tag
            className={style.btnphone}
            title={'Перезвонить'}
            color="second"
            as={'button'}
            type="filled"
            onClick={contactUs.open}
          /> */}
          {/* <div className={style.btnphone}>
              <button</button>
            </div> */}
        </div>
        <button className={style.menu} onClick={handleToggleClick} aria-label="toggle" type="button">
          Меню
        </button>
      </nav>
    </header>
  );
}

export default Header2;
