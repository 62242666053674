import React from 'react';
import cn from 'classnames';
import { scroller } from 'react-scroll';

import style from './HeaderItem2.module.css';

interface Props {
  children: React.ReactNode;
  to: string;
  id: number;
  className?: string;
  onClick: (id: number) => void;
}

function HeaderItem2({ children, to, id, className, onClick }: Props): JSX.Element {
  const handleClick = () => {
    scroller.scrollTo(to, {
      duration: 800,
      smooth: true,
      offset: 0,
    });
    onClick(id);
  };

  return (
    <div onClick={handleClick} className={style.HeaderItem}>
      {/* <li className={cn(style.item, className)}>{children}</li> */}
      <li className={className}>{children}</li>
    </div>
  );
}
export default HeaderItem2;
