// import img1 from '../../images/AdvantagesConvenience.png';
// export const DigitalPlatformSections1 = [
//   {
//     Id: 0,
//     Name: 'Мониторинг / Единый портал',
//     Items: [
//       {
//         Id: 0,
//         Name: 'Архив ранее пробуренных скважин (Арбитраж)',
//       },
//       {
//         Id: 1,
//         Name: 'Ввод шламограммы',
//       },
//       {
//         Id: 2,
//         Name: 'Сбор данных ГТИ MDW',
//       },
//       {
//         Id: 3,
//         Name: 'Контроль КПЭ / Оповещения об отклонениях',
//       },
//       {
//         Id: 4,
//         Name: 'Сбор данных Телеметрии LWD',
//       },
//       {
//         Id: 5,
//         Name: 'Эл. документооборот',
//       },
//       {
//         Id: 6,
//         Name: 'img1',
//         Url: img1,
//       },
//     ],
//   },
//   {
//     Id: 1,
//     Name: 'Аналитика СНПВ',
//     Items: [],
//   },
//   {
//     Id: 2,
//     Name: 'Суточный рапорт (DDR)',
//     Items: [],
//   },
//   {
//     Id: 3,
//     Name: 'Drilling Cost',
//     Items: [],
//   },
//   {
//     Id: 4,
//     Name: 'Учет наработки инструмента',
//     Items: [],
//   },
//   {
//     Id: 5,
//     Name: 'Дизайн-проект программы бурения',
//     Items: [],
//   },
//   {
//     Id: 6,
//     Name: 'Электронное дело скважины',
//     Items: [],
//   },
// ];
// export const DigitalPlatformTabs1 = [
//   { Id: 0, Name: 'Все', Items: DigitalPlatformSections1 },
//   { Id: 1, Name: 'Нефтесервисной компании', Items: DigitalPlatformSections1 },
//   { Id: 2, Name: 'Буровой компании', Items: DigitalPlatformSections1 },
//   { Id: 3, Name: 'Супервайзерам', Items: DigitalPlatformSections1 },
//   { Id: 4, Name: 'Недропользователям', Items: DigitalPlatformSections1 },
//   { Id: 5, Name: 'Центрам инжиниринга', Items: DigitalPlatformSections1 },
// ];
import img1 from '../../images/products/1.png';
import img2 from '../../images/products/2.png';
import img3 from '../../images/products/3.png';
import img4 from '../../images/products/4.png';
import img5 from '../../images/products/5.png';
import img6 from '../../images/products/6.png';
import img7 from '../../images/products/7.png';
import { IProduct, MonitoringDPConstant } from '../DigitalPlatform/items/1MonitoringConstants';
import { AnaliticsDPConstant } from '../DigitalPlatform/items/2AnaliticsConstants';
import { RaportDPConstant } from '../DigitalPlatform/items/3RaportConstants';
import { AccountingToolsDPConstant } from '../DigitalPlatform/items/5AccountingToolsConstants';
import { PlanDPConstant } from '../DigitalPlatform/items/6PlanConstants';
import { WellDPConstant } from '../DigitalPlatform/items/7WellConstants';
import { CostsDPConstant } from '../DigitalPlatform/items/4CostsConstants';
import { TrajectoryDPConstant } from '../DigitalPlatform/items/8TrajectoryConstants';

export const DigitalPlatformSections1 = [
  {
    Id: 0,
    EnName: 'Monitoring',
    Img: img1,
    Name: 'Мониторинг бурения онлайн',
    Product: MonitoringDPConstant,
    // Component: <Monitoring1
    Items: [
      {
        Id: 0,
        Name: 'Портал мониторинга (все скважины в одном окне)',
      },
      {
        Id: 1,
        Name: 'Архив ранее пробуренных скважин',
      },
      {
        Id: 2,
        Name: 'Сбор данных ГТИ и СКПБ',
      },
      {
        Id: 3,
        Name: 'Сбор данных от Телесистем LWD и MDW',
      },
      {
        Id: 4,
        Name: 'Ввод шламограммы',
      },
      {
        Id: 5,
        Name: 'Контроль операций',
      },
      {
        Id: 6,
        Name: 'Электронный документооборот',
      },
    ],
  },
  {
    Id: 1,
    EnName: 'Trajectory',
    Img: img2,
    Name: 'Траектория на Web-платформе',
    Product: TrajectoryDPConstant,
    Items: [
      {
        Id: 0,
        Name: 'Геопортал',
      },
      {
        Id: 1,
        Name: 'Сбор данных LWD/ MWD и ГТИ',
      },
      {
        Id: 2,
        Name: 'Контроль проводки ствола скважины',
      },
      {
        Id: 3,
        Name: 'Контроль операций',
      },
      {
        Id: 4,
        Name: 'Электронный документооборот',
      },
    ],
  },
  {
    Id: 2,
    EnName: 'Analitics',
    Img: img3,
    Name: 'Аналитика Скрытого НПВ',
    Product: AnaliticsDPConstant,
    Items: [
      {
        Id: 0,
        Name: 'Отчеты по СНПВ',
      },
    ],
  },
  {
    Id: 3,
    EnName: 'Raport',
    Img: img4,
    Name: 'Суточный рапорт бурения',
    Product: RaportDPConstant,
    Items: [
      {
        Id: 0,
        Name: 'Подготовка за 15 минут',
      },
      {
        Id: 1,
        Name: 'Корпоративная база данных',
      },
      {
        Id: 2,
        Name: 'Интеграция с 1С',
      },
    ],
  },
  {
    Id: 4,
    EnName: 'Costs',
    Img: img5,
    Name: 'Управление затратами на бурение',
    Product: CostsDPConstant,
    Items: [
      {
        Id: 0,
        Name: 'Планирование с учетом опыта',
      },
      {
        Id: 1,
        Name: 'Калькуляция стоимости',
      },
      {
        Id: 2,
        Name: 'Анализ затрат план/факт',
      },
    ],
  },
  {
    Id: 5,
    EnName: 'AccountingTools',
    Img: img6,
    Name: 'Учет наработки инструмента',
    Product: AccountingToolsDPConstant,
    Items: [
      {
        Id: 0,
        Name: 'Прогнозирование и профилактика',
      },
      {
        Id: 1,
        Name: 'Наработка элементов КНБК',
      },
      {
        Id: 2,
        Name: 'Отработка долот',
      },
    ],
  },
  {
    Id: 6,
    EnName: 'Plan',
    Img: img7,
    Name: 'Планирование',
    Product: PlanDPConstant,
    Items: [
      {
        Id: 0,
        Name: ' Дизайн-проект программы бурения',
      },
      {
        Id: 1,
        Name: 'График движения буровых блоков',
      },
      {
        Id: 2,
        Name: 'Сетевой квартальный график',
      },
      {
        Id: 3,
        Name: 'Оперативный график',
      },
    ],
  },
  {
    Id: 7,
    EnName: 'Well',
    Img: null,
    Name: 'Электронное дело скважины',
    Product: WellDPConstant,
    Items: [
      {
        Id: 0,
        Name: 'Извлечение накопленного опыта',
      },
      {
        Id: 1,
        Name: 'Хранение и передача данных',
      },
    ],
  },
];
const filterItemsByIds: FilterItemsByIds = (items, ids) => {
  return items.filter(item => ids.includes(item.Id)) || [];
};
export const DigitalPlatformTabs1: Tab[] = [
  { Id: 0, Name: 'Все', Items: DigitalPlatformSections1 },
  { Id: 1, Name: 'Недропользователи', Items: filterItemsByIds(DigitalPlatformSections1, [0, 1, 2, 3, 4, 6, 7]) },
  { Id: 2, Name: 'Нефтесервисной компании', Items: filterItemsByIds(DigitalPlatformSections1, [0, 1, 2, 4, 5, 6, 7]) },
  { Id: 3, Name: 'Буровой компании', Items: filterItemsByIds(DigitalPlatformSections1, [0, 1, 2, 4, 5]) },
  { Id: 4, Name: 'Супервайзерам', Items: filterItemsByIds(DigitalPlatformSections1, [0, 1, 2]) },
  { Id: 5, Name: 'Наклонно-направленный сервис', Items: filterItemsByIds(DigitalPlatformSections1, [0, 2, 4, 7]) },
  { Id: 6, Name: 'Центрам инжиниринга', Items: DigitalPlatformSections1 },
];
type Item = {
  Id: number;
  Name: string;
  Url?: string;
};

export type ISection = {
  Id: number;
  Img: string | null;
  Name: string;
  Product: IProduct;
  EnName: string;
  Items: Item[];
};

type Tab = {
  Id: number;
  Name: string;
  Items: ISection[];
};

type FilterItemsByIds = (items: ISection[], ids: number[]) => ISection[];
