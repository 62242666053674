import React, { forwardRef } from 'react';
import cn from 'classnames';

import Tag from '../UIkit/Tag';
import TransitArrow from '../SVG/TransitArrow';
import EyeIcon from '../SVG/EyeIcon';
import NavigationButton from '../UIkit/NavigationButton';

import style from './Card1.module.css';

interface Props {
  title: string;
  tag: string;
  children?: string;
  img?: string;
  views?: string;
  isActive?: boolean;
  isBig?: boolean;
  onClick: () => any;
}
function Card1({ isActive, title, tag, children, img, isBig, views }: Props): JSX.Element {
  return (
    <div
      className={cn(style.card, isActive && style.active, isBig && style.big, img && style.img)}
      style={{ backgroundImage: img ? `url(${img})` : 'none', justifyContent: views ? 'space-between' : 'start' }}
    >
      <div className={style.header}>
        <div className={style.header__box}>
          <Tag className={style.header__tag} title={'IT КОМПАНИЯ INNERICA'} color="white" type="filled" />
          <Tag className={style.header__tag} title={'АО «СИБИРСКАЯ СЕРВИСНАЯ КОМПАНИЯ»'} color="white" type="filled" />
          {/* <div className={style.header__date}>24.03.2024</div> */}
        </div>
        {/* <TransitArrow className={style.arrow} /> */}
        <div className={style.timebox}>
          <EyeIcon width={20} color="white" />
          <div className={style.time}>10 минут</div>
        </div>
      </div>
      <div className={style.title}>{title}</div>
      <div className={style.text}>{children && children}</div>
      <NavigationButton
        title="Читать полностью"
        variant="outline"
        color="white"
        as={'a'}
        href={'https://online.runeft.ru/5/innerica'}
      />
      {views && (
        <div className={style.views}>
          <div>{views}</div>
          <EyeIcon />
        </div>
      )}
    </div>
  );
}

export default Card1;
