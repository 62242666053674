import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import NavigationLink from '../UIkit/NavigationLink';
// import { FACILITIES } from './constants';
import video from '../../videos/promo.mp4';
import CounterAnimation from '../../utils/CounterAnimation';
import Tag from '../UIkit/Tag';
import { isElementInViewport } from '../../utils/isElementInViewport';
import skolkovo from '../../images/skolkovo.png';
import LogoSkolkovo from '../../images/LogoSkolkovo.png';
import RoundButtonTransitArrow from '../UIkit/RoundButtonTransitArrow';
import { useElementVisibility } from '../../utils/useElementVisibility';

import style from './Video2.module.css';
import Video2Btn from './Video2Btn';

// interface Props {
// isVisible: boolean;
// }

const Video2 = forwardRef<HTMLElement>((props, ref) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  // console.log(isPlaying);

  const togglePlay = () => {
    console.log('togglePlay');

    if (!videoRef.current) return;
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
    // e.stopPropagation();
  };
  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    console.log('handlePause');

    setIsPlaying(false);
  };
  // const [isVisible, setVisibale] = useState(false);
  // function handleScroll() {
  //   const items = document.querySelectorAll(`.${style.box}`);
  //   items.forEach(item => {
  //     if (isElementInViewport(item as HTMLElement)) {
  //       //todo: по каждому анимация
  //       setVisibale(true);
  //     }
  //   });
  // }
  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  const triggerRef = useRef<HTMLDivElement>(null);
  const isVisible = useElementVisibility(triggerRef);
  return (
    <article ref={ref} className={style.container} id="company">
      <div className={style.header}>
        <Tag title="Мы в цифрах" color="grey" />
        <h2>
          О компании <span>INNERICA</span>
        </h2>
      </div>
      {/* <div className={style.main}>
        <div className={style.box}>
          <h4 className={style.number}>
            <CounterAnimation start={1} end={15000} duration={2} isVisible={isVisible} />+
          </h4>
          <p className={style.text}>
            скважин построено <br />с нами
          </p>
        </div>
        <div className={style.box}>
          <h4 className={style.number}>
            <CounterAnimation start={1950} end={2003} duration={2} isVisible={isVisible} />
          </h4>
          <p className={style.text}>
            год основания <br />
            IT-компании
          </p>
        </div>
        <div className={style.box}>
          <h4 className={style.number}>
            <CounterAnimation start={10} end={20} duration={2} isVisible={isVisible} />+{' '}
          </h4>
          <p className={style.text}>
            лет в <br />
            нефтегазе
          </p>
        </div>
        <div className={style.box}>
          <h4 className={style.number}>
            <CounterAnimation start={20} end={50} duration={2} isVisible={isVisible} />+{' '}
          </h4>
          <p className={style.text}>
            разработчиков <br />в команде
          </p>
        </div>
      </div> */}
      <div className={style.main} ref={triggerRef}>
        <div className={style.box}>
          <h4 className={style.number}>
            <CounterAnimation start={1} end={15000} duration={2} isVisible={isVisible} />+
          </h4>
          <div className={style.box__btns}>
            <Tag color="white" type="filled" title="скважин построено с нами" />
            <RoundButtonTransitArrow />
          </div>
        </div>
        <div className={style.box}>
          {/* <h4 className={style.number}>2003 </h4> */}
          <h4 className={style.number}>
            <CounterAnimation start={1950} end={2003} duration={2} isVisible={isVisible} />
          </h4>
          <div className={style.box__btns}>
            <Tag color="white" type="filled" title="год основания" />
            <RoundButtonTransitArrow />
          </div>
        </div>
        <div className={style.box}>
          <h4 className={style.number}>
            {/* 20 */}
            <CounterAnimation start={10} end={20} duration={2} isVisible={isVisible} />+{' '}
          </h4>
          <div className={style.box__btns}>
            <Tag color="white" type="filled" title="лет опыта в области нефтегаза" />
            <RoundButtonTransitArrow />
          </div>
        </div>
        <div className={style.box}>
          <h4 className={style.number}>
            {/* 50 */}
            <CounterAnimation start={20} end={50} duration={2} isVisible={isVisible} />+{' '}
          </h4>
          <div className={style.box__btns}>
            <Tag color="white" type="filled" title="разработчиков в команде" />
            <RoundButtonTransitArrow />
          </div>
        </div>
      </div>
      <div className={style.bottombox}>
        <div className={style.bottombox__left}>
          <div className={style.tags}>
            <Tag color="grey" type="filled" title="50 патентов" />
            <Tag color="grey" type="filled" title="Имеется статус МТК" />
            <Tag color="grey" type="filled" title="Аккредитованная IT компания в реестре Минцифры" />
            <Tag color="grey" type="filled" title="В реестре отечественного ПО" />
          </div>
          <div className={style.imgbox}>
            <img className={style.img} src={skolkovo} alt="skolkovo" />
          </div>
          <div className={style.card}>
            <div className={style.logoSkolkovo}></div>
            {/* <img src={LogoSkolkovo} alt="LogoSkolkovo" /> */}
            <div className={style.card__btns}>
              <Tag color="white" type="filled" title="Участник Сколково" />
              <RoundButtonTransitArrow />
            </div>
          </div>
        </div>
        <div className={style.videobox}>
          {/* <video ref={videoRef} className={style.video} onPlay={handlePlay} onPause={handlePause} controls 
          onCanPlay={() => console.log("Video is ready")}>*/}
          <video ref={videoRef} className={style.video} onClick={togglePlay}>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {!isPlaying && <Video2Btn togglePlay={togglePlay} />}
        </div>
      </div>
    </article>
  );
});
Video2.displayName = 'Video2';
export default Video2;
