import img from '../../../images/products/2.png';
export const TrajectoryDPConstant = {
  name: 'Траектория на Web-платформе',
  img: img,
  text: `Дистанционный сервис сопровождения траектории 3D-профиля ствола скважины по данным телеметрических станций ННБ Сервиса.`,
  list: [
    `Подключение к телесистемам (LWD/MWD) и ГТИ-станциям для получения данных в режиме реального времени.`,
    `3D проектная и фактическая траектории ствола скважины.`,
    `Контроль сближения  с «соседками», выхода за коридор допуска, интенсивности искривления, попадания в круг допуска`,
  ],
  effects: [
    `Повышение оперативности реагирования для центра управления во время бурения`,
    `Уменьшение рисков аварий благодаря мгновенным оповещениям`,
  ],
  items: [
    {
      Id: '01',
      Title: `Геопортал`,
      Text: ` Наш портал позволяет контролировать все скважины в реальном времени.Это удобно для оперативного контроля и анализа.`,
    },
    {
      Id: '02',
      Title: `Сбор данных LWD/ MWD и ГТИ`,
      Text: `Возможность подключаться и собирать данные от любого типа телесистемы LWD/MWD и ГТИ-станции, что позволяет оперативно корректировать процесс бурения.`,
    },
    {
      Id: '03',
      Title: `Контроль проводки ствола скважины`,
      Text: `Построение 3D проектной и фактической траектории ствола скважин.
Контроль сближения  с «соседками», выхода за коридор допуска, интенсивности искривления, попадания в круг допуска.`,
    },
    {
      Id: '04',
      Title: `Контроль операций`,
      Text: ` Система автоматически распознает буровые операции по технологическим параметрам ГТИ.`,
    },
    {
      Id: '05',
      Title: `Электронный документооборот`,
      Text: ` Автоматизируйте оформление и согласование документов.Все данные хранятся в единой системе, что упрощает доступ и поиск.`,
    },
  ],
};
