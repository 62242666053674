import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import style from './DropdownMenu.module.css';

interface DropdownItem {
  label: string;
  to: string;
}

interface DropdownProps {
  title: string;
  items: DropdownItem[];
  className?: string;
}

function DropdownMenu({ title, items, className }: DropdownProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLLIElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <li
      ref={dropdownRef}
      className={cn(style.dropdown, className)}
      // onMouseEnter={() => setIsOpen(true)}
      // onMouseLeave={() => setIsOpen(false)}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={style.dropdownTitle}>{title}</div>
      {isOpen && (
        <ul className={style.dropdownContent}>
          {items.map((item, index) => (
            <Link key={index} to={item.to} className={style.dropdownItem}>
              <li>{item.label}</li>
            </Link>
            // <li key={index} className={style.dropdownItem}>
            //   <a href={item.to}>{item.label}</a>
            // </li>
          ))}
        </ul>
      )}
    </li>
  );
}

export default DropdownMenu;
