import React, { useState } from 'react';
import { Formik, Field, Form, FormikProps } from 'formik';
import cn from 'classnames';

import { StoreContext } from '../../store';
import Input from '../Inputs/Input';
import Dropdown from '../Inputs/Dropdown';
import SubmitButton from '../Buttons/SubmitButton';
import Tag from '../UIkit/Tag';
import Checkbox from '../Buttons/Checkbox';

import Schema1 from './FormContactUsSchema1';
import style from './FormContactUs.module.css';

type Item = {
  value: string;
  id: number;
};

type MyFormValues = {
  name: string;
  email: string;
  companyName: string;
  equipment: string;
  companyType: Item | null;
};

type Props = {
  className?: string;
};

const companyTypeItems = [
  { value: 'Нефтяная / Газовая', id: 1 },
  { value: 'Буровая / Сервисная', id: 2 },
  { value: 'Производитель оборудования', id: 3 },
  { value: 'Другая', id: 4 },
];

type T = Window & typeof globalThis;
interface MyWindow extends T {
  ['Infinity']: any;
  ['NaN']: any;
  env: {
    REACT_APP_API_URL: string;
  };
}

function FormContactUs({ className }: Props): JSX.Element {
  const { success, failed, preloader } = React.useContext(StoreContext);
  // const handleSubmit = (values: MyFormValues): void => {
  // };

  async function sendMail(values: any) {
    try {
      preloader.open();
      await fetch(`${(window as MyWindow).env.REACT_APP_API_URL}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      preloader.close();
      success.open();
    } catch (error) {
      preloader.close();
      failed.open();
    }
  }

  const handleSubmit = (values: MyFormValues, actions: any): void => {
    const postValues = {
      name: values.name,
      company: values.companyName,
      email: values.email,
      ['company_type']: values.companyType ? values.companyType.value : '',
      equipment: values.equipment,
    };
    sendMail(postValues);
    actions.resetForm({
      name: '',
      email: '',
      companyName: '',
      equipment: '',
      companyType: null,
    });
  };

  function renderForm({ errors, touched }: FormikProps<any>): JSX.Element {
    return (
      <Form className={style.form}>
        <Field fullWidth type="text" name="name" component={Input} placeholder={'Как Вас зовут?'} />
        <Field fullWidth type="text" name="email" component={Input} placeholder={'Ваш e-mail'} />
        <Field fullWidth type="text" name="companyName" component={Input} placeholder={'Название Вашей компании'} />
        <Field fullWidth type="text" name="equipment" component={Input} placeholder={'Ваше оборудование'} />
        <Field
          fullWidth
          name="companyType"
          component={Dropdown}
          placeholder={'Тип Вашей компании'}
          items={companyTypeItems}
        />
        {/* <Field
          name="agree"
          label="Я согласен с условиями"
          component={Checkbox}
        /> */}
        <Field name="agree" component={Checkbox} />
        {touched.agree && errors.agree && <div style={{ color: 'red' }}>{errors.agree}</div>}
        <SubmitButton title="Отправить" className={style.button} small />
      </Form>
    );
  }

  return (
    <div className={cn(style.container, className)}>
      <div className={style.itemActive}>
        <div>
          <Tag className={style.tag} title="Оставить заявку" color="white" />
          <h2 className={style.header}>Как мы можем помочь Вам?</h2>
        </div>
        <p>
          Оставьте свои контактные данные, мы свяжемся с Вами в ближайшее время. Ответим на вопросы и обсудим задачи
          вашей компании.
        </p>
      </div>
      <div className={style.itemImg}>
        <Formik
          initialValues={{
            name: '',
            email: '',
            companyName: '',
            equipment: '',
            companyType: null,
            agree: false,
          }}
          validationSchema={Schema1}
          onSubmit={handleSubmit}
          render={renderForm}
        />
      </div>
    </div>
  );
}

export default FormContactUs;
