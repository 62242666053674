import { log } from 'console';

import React, { useState, forwardRef, useEffect } from 'react';
import cn from 'classnames';
import useInterval from 'use-interval';

import Tag from '../UIkit/Tag';
import { useResize } from '../../utils/hooks';

import { Review1 as ReviewType } from './types';
import ReviewItem1 from './ReviewItem1';
import ReviewScroll1 from './ReviewScroll1';
import ReviewMock1 from './ReviewMock1.json';
import style from './Review1.module.css';

// interface Props {}
const Review1 = forwardRef<HTMLElement>((props, ref) => {
  const { isScreen900 } = useResize();
  const [currentReview, setCurrentReview] = useState(1);
  const { length } = ReviewMock1;

  useEffect(() => {
    setCurrentReview(1);
  }, [isScreen900]);
  useEffect(() => {
    if (currentReview - length > 0) {
      setCurrentReview(1);
    }
  }, [currentReview, length]);
  // console.log(currentReview);
  // useInterval(() => {
  //   if (currentReview - length > 0) {
  //     setCurrentReview(0);
  //   }else{
  //     setCurrentReview((prev) => (prev + 1));
  //   }
  // }, 5000);
  return (
    <article ref={ref} className={style.container} id="review1">
      <div className={style.header}>
        <h2 className={style.h2}>
          Мнение наших клиентов <span>о нас</span>
        </h2>
        <Tag title={'Отзывы'} color="grey" />
      </div>
      <div className={style.itemcontainer}>
        <div
          className={style.wrapper}
          style={{
            transform: `translate(${isScreen900 ? (currentReview - 1) * -100 : (currentReview - 1) * -50}%,0)`,
          }}
        >
          {/* {[...ReviewMock1, ReviewMock1[0]].map((item: ReviewType, index: number) => { */}
          {ReviewMock1.map((item: ReviewType, index: number) => {
            let isVisible;
            if (isScreen900) {
              if (currentReview == 1) isVisible = index === 0 || index === 1;
              if (currentReview == 2) isVisible = index === 2 || index === 3;
              if (currentReview == 3) isVisible = index === 4 || index === 0;
            } else {
              isVisible = index === currentReview - 1;
            }
            // const isVisible = index === currentReview || index === currentReview;
            // console.log(isVisible);

            return (
              <ReviewItem1
                key={item.id}
                review={item}
                className={cn(style.item)}
                // className={cn(style.item, isVisible && style.visible, style[`position${index}`])}
                // className={cn(style.item, isVisible ? style.visible : style.hidden)}
              />
            );
            // <ReviewItem1
            //   key={item.id}
            //   review={item}
            //   className={cn(
            //     style.item,
            //     index === currentReview && style.first,
            //     index === (currentReview + 1) % length && style.second,
            //     index === (currentReview + 2) % length && style.right,
            //   )}
            // />)
          })}
        </div>
      </div>
      <ReviewScroll1 current={currentReview} reviews={ReviewMock1} setCurrentReview={setCurrentReview} />
    </article>
  );
});
Review1.displayName = 'Review1';
export default Review1;
