import img from '../../../images/products/1.png';
export const MonitoringDPConstant = {
  name: 'Мониторинг бурения онлайн',
  img: img,
  text: `Цифровая платформа INNERICA предоставляет комплексный инструмент для управления и повышения эффективности бурения — удаленный онлайн мониторинга бурения. Это позволяет оперативно выявлять отклонения, предотвращать аварии и оптимизировать процесс бурения`,
  list: [
    `Интеграция  с системами ГТИ, СКПБ и телесистем (LWD/MWD)
для получения данных в режиме реального времени`,
    `Возможность визуализации данных через интуитивно понятные графики и дашборды`,
    `Поддержка работы в сложных условиях, включая морские платформы и удаленные месторождения`,
  ],
  effects: [
    `Снижение времени на анализ данных за счет автоматизации`,
    `Уменьшение рисков аварий и простоев благодаря мгновенным оповещениям`,
    `Сбор, структурирование и мгновенный доступ к онлайн и историческим данным`,
  ],
  items: [
    {
      Id: '01',
      Title: `Портал мониторинга
(все скважины в одном окне)`,
      Text: `Наш портал объединяет данные по всем скважинам, включая глубину, скорость проходки, давление и температуру. Это удобно для оперативного контроля и анализа`,
    },
    {
      Id: '02',
      Title: `Архив ранее пробуренных скважин`,
      Text: `Доступ к историческим данным помогает избежать ошибок при бурении новых скважин. Вы можете анализировать прошлый опыт и улучшать текущие процессы`,
    },
    {
      Id: '03',
      Title: `Сбор данных ГТИ и СКПБ`,
      Text: `Автоматический сбор данных геолого-технологических исследований (ГТИ) и станций контроля параметров бурения (СКПБ) позволяет оперативно анализировать пласты и корректировать процесс бурения`,
    },
    {
      Id: '04',
      Title: `Сбор данных от Телесистем LWD и MDW`,
      Text: `Получайте данные о параметрах скважины в режиме реального времени. Это особенно важно для сложных участков, где требуется высокая точность`,
    },
    {
      Id: '05',
      Title: `Ввод шламограммы`,
      Text: `Упростите анализ состава пород с помощью автоматизированного ввода данных шламограммы. Это помогает точнее определять границы пластов`,
    },
    {
      Id: '06',
      Title: `Контроль операций`,
      Text: `Система автоматически распознает буровые операции по технологическим параметрам ГТИ или СКПБ`,
    },
    {
      Id: '07',
      Title: `Электронный документооборот`,
      Text: `Автоматизируйте оформление и согласование документов. Все данные хранятся в единой системе, что упрощает доступ и поиск`,
    },
  ],
};

export type IProduct = {
  name: string;
  img: string | null;
  text: string;
  list: string[];
  effects: string[];
  items: {
    Id: string;
    Title: string;
    Text: string;
  }[];
};
