import React, { forwardRef } from 'react';
import cn from 'classnames';

import Tag from '../UIkit/Tag';
import article1 from '../../images/articles/article1.jpg';

import style from './Blog1.module.css';
import Card from './Card1';

// interface Props {}
const Blog = forwardRef<HTMLElement>((props, ref) => {
  // https://online.runeft.ru/5/innerica
  return (
    <article ref={ref} className={style.container}>
      <div className={style.header} id="blog1">
        <Tag className={style.header__tag} title={'Статьи'} color="grey" />
        <h2 className={style.h2}>Наш блог</h2>
      </div>
      <div className={style.grid}>
        <Card tag="Lorem ipsum" title="Опыт цифровой трансформации" isBig onClick={() => {}} img={article1}>
          Повышение эффективности бурения скважин является приоритетной задачей для нефтегазовых и нефтесервисных
          компаний в условиях роста конкуренции и повышения себестоимости строительства скважин. Одним из инструментов
          достижения этой цели становится платформенная цифровизация супервайзинга буровых работ в региональных
          подразделениях и технологических сервисах — ННБ, КНБК, буровые растворы, цементирование...
        </Card>
      </div>
    </article>
  );
});
Blog.displayName = 'Blog';

export default Blog;
