import * as Yup from 'yup';

const FooterFormSchema1 = Yup.object().shape({
  name: Yup.string()
    .required('Обязательное поле')
    .min(5, 'Должно быть длиннее 5 символов')
    .max(120, 'Должно быть короче 120 символов'),
  email: Yup.string()
    .email('Введите правильный e-mail')
    .required('Обязательное поле')
    .min(5, 'Должно быть длиннее 5 символов')
    .max(120, 'Должно быть короче 120 символов'),
  companyName: Yup.string()
    .required('Обязательное поле')
    .min(5, 'Должно быть длиннее 5 символов')
    .max(120, 'Должно быть короче 120 символов'),
  equipment: Yup.string()
    .required('Обязательное поле')
    .min(5, 'Должно быть длиннее 5 символов')
    .max(120, 'Должно быть короче 120 символов'),
  companyType: Yup.string()
    .nullable()
    .required('Обязательное поле'),
  agree: Yup.boolean()
    .oneOf([true], 'Вы должны согласиться с условиями')
    .required('Обязательное поле'),
});

export default FooterFormSchema1;
