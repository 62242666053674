import React from 'react';
import { animateScroll } from 'react-scroll';

// import style from './Footer1.module.css';

function FooterBy1(): JSX.Element {
  function scrollToTop(): void {
    animateScroll.scrollToTop();
  }

  // return (
  //   <footer className={style.footer}>
  //     {/* <div className={style.animation}> */}
  //     {/* <FooterAnimation /> */}
  //     {/* </div> */}
  //     <FooterForm className={style.form} />
  //     <div className={style.container}>
  //       <ul className={style.list}>
  //         <li className={style.logoContainer}>
  //           <button className={style.logo} onClick={scrollToTop} aria-label="logo" />
  //         </li>
  //         <li>
  //           <p className={style.title}>Оставайтесь с нами</p>
  //           <ul>
  //             <li>
  //               <a href="tel:+375(29)970-76-06">+375 (29) 970-76-06</a>
  //             </li>
  //             <li>(WhatsApp, Viber и Telegram)</li>
  //             <li>
  //               <a href="mailto:main.office@innerica.by">main.office@innerica.by</a>
  //             </li>
  //           </ul>
  //         </li>
  //         <li>
  //           <p className={style.title}>Офис</p>
  //           <ul>
  //             <li>г. Минск, ул. Логойский тракт, д. 22А</li>
  //           </ul>
  //         </li>
  //         {/* <li className={style.fingersContainer}>
  //           <a href="https://fingers-media.com/" className={style.fingers} target="_blank" rel="noopener noreferrer">
  //              <img src={fingers} alt="Fingers Media" title="Fingers Media" />
  //             Дизайн и разработка
  //           </a>
  //         </li> */}
  //       </ul>
  //     </div>
  //   </footer>
  // );
  return <div></div>;
}

export default FooterBy1;
