import React, { forwardRef } from 'react';
import { animateScroll, scroller } from 'react-scroll';
import { Link, useHistory } from 'react-router-dom';

import snTelegram from '../../../images/sn_icon_telegram.svg';
import snWhatsup from '../../../images/sn_icon_whatsapp.svg';
import logo from '../../../images/HeaderLogo1.svg';

import style from './Footer1.module.css';

// interface Props {}
const Footer1 = forwardRef<HTMLElement>((props, ref) => {
  const history = useHistory();

  const handleClick = (to: string) => {
    //note: если в header footer будут страницы еще кроме главной
    // if (window.location.pathname === '/') {
    //   scroller.scrollTo(to, {
    //     duration: 800,
    //     smooth: true,
    //     offset: 0
    //   });
    // } else {
    history.push({
      pathname: '/',
      state: { scrollTo: to },
    });
    // }
  };

  const menuItems = [
    { to: 'platform1', label: 'Платформа', id: 1 },
    { to: 'company', label: 'О компании', id: 3 },
    { to: 'clients1', label: 'Клиенты', id: 7 },
    { to: 'review1', label: 'Отзывы', id: 9 },
  ];

  return (
    <footer ref={ref} className={style.footer}>
      <div className={style.container}>
        <ul className={style.list}>
          <li className={style.logoContainer}>
            <Link to={`/`}>
              <img className={style.logo} src={logo} alt="logo" />
            </Link>
          </li>
          <li>
            <div className={style.item}>
              <p className={style.title}>Контакты</p>
              <a href="tel:+7(985)305-05-41">+7 (985) 305-05-41</a>
              <a href="mailto:office@innerica.ru">office@innerica.ru</a>
              <div className={style.icons}>
                <img src={snTelegram} alt="telegram" />
                <img src={snWhatsup} alt="whatsup" />
              </div>
            </div>
            <div className={style.item}>
              <p className={style.title}>Офис</p>
              <div>г. Москва, Можайское шоссе, д.29</div>
            </div>
          </li>
          <li>
            <p className={style.title}>Навигация</p>
            <ul>
              {menuItems.map(item => {
                return (
                  <li key={`Footer_${item.to}`} style={{ cursor: 'pointer' }} onClick={() => handleClick(item.to)}>
                    {item.label}
                  </li>
                );
              })}
            </ul>
          </li>
        </ul>
      </div>
    </footer>
  );
});
Footer1.displayName = 'Footer1';

export default Footer1;
