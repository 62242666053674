import React from 'react';
import cn from 'classnames';

type Props = {
  color?: string;
  opacity?: string;
  className?: string;
  width?: number;
  height?: number;
  strokeWidth?: string;
};

function TransitArrow({
  color = 'black',
  opacity = '1',
  className,
  width = 44,
  height = 42,
  strokeWidth = '1.63586',
}: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 44 42"
      fill="none"
      className={cn(className)}
    >
      <g opacity={opacity}>
        <line x1="1.09742" y1="41.4216" x2="41.715" y2="0.804014" stroke={color} strokeWidth={strokeWidth} />
        <path d="M2.79102 1.10352L42.387 1.10352" stroke={color} strokeWidth={strokeWidth} />
        <line x1="42.648" y1="1.38232" x2="42.648" y2="40.4206" stroke={color} strokeWidth={strokeWidth} />
      </g>
    </svg>
  );
}

export default TransitArrow;
