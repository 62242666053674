import React, { useState, useEffect, useRef } from 'react';
interface Props {
  start: number;
  end: number;
  duration: number;
  isVisible: boolean;
}
const CounterAnimation = ({ start = 1, end, duration = 5, isVisible }: Props): JSX.Element => {
  const [count, setCount] = useState(start);
  const requestRef = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  useEffect(() => {
    if (!isVisible) return;

    const animate = (timestamp: number) => {
      if (!startTimeRef.current) startTimeRef.current = timestamp;
      const progress = timestamp - startTimeRef.current;
      const percentage = Math.min(progress / (duration * 1000), 1);
      const currentCount = Math.floor(percentage * (end - start) + start);
      // console.log(currentCount);

      setCount(currentCount);

      if (percentage < 1) {
        requestRef.current = requestAnimationFrame(animate);
      }
    };

    requestRef.current = requestAnimationFrame(animate);

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [isVisible, end, duration]);

  return <>{count}</>;
};

export default CounterAnimation;
