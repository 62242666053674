// import React, { useEffect, useRef } from 'react';
// import Scrollbar from 'smooth-scrollbar';

// const SmoothScrollWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
//   const scrollContainerRef = useRef<HTMLDivElement>(null);

//   useEffect(() => {
//     if (scrollContainerRef.current) {
//       const scrollbar = Scrollbar.init(scrollContainerRef.current, {
//         damping: 0.1,
//         thumbMinSize: 20,
//         renderByPixels: true,
//         alwaysShowTracks: false,
//         continuousScrolling: true,
//       });

//       return () => {
//         if (scrollbar) {
//           scrollbar.destroy();
//         }
//       };
//     }
//   }, []);

//   return (
//     <div ref={scrollContainerRef} style={{ height: '100vh', overflow: 'hidden' }} className="your-scroll-container">
//       {children}
//     </div>
//   );
// };

// export default SmoothScrollWrapper;
import React, { useEffect, useRef } from 'react';
import Scrollbar from 'smooth-scrollbar';
import { scroller } from 'react-scroll';

type ScrollOptions = {
  duration?: number;
  smooth?: boolean;
  offset?: number;
};

const SmoothScrollWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollbarRef = useRef<Scrollbar | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollContainerRef.current) return;
    if (scrollContainerRef.current) {
      const scrollbar = Scrollbar.init(scrollContainerRef.current, {
        damping: 0.1,
        thumbMinSize: 20,
        renderByPixels: true,
        alwaysShowTracks: false,
        continuousScrolling: true,
      });

      scrollbarRef.current = scrollbar;

      const originalScrollTo = scroller.scrollTo;
      // const originalScrollTo = scroller.scrollTo.bind(scroller);

      scroller.scrollTo = (to: string, options?: ScrollOptions) => {
        const { duration = 500, smooth = true, offset = 0 } = options || {};
        const target = document.getElementById(to);

        if (target && scrollbar) {
          const targetRect = target.getBoundingClientRect();
          const scrollTop = scrollbar.scrollTop;
          const targetTop = targetRect.top + scrollTop + offset;

          if (smooth) {
            scrollbar.scrollTo(0, targetTop, duration);
          } else {
            scrollbar.setPosition(0, targetTop);
          }
        } else {
          originalScrollTo(to, options);
        }
      };

      return () => {
        if (scrollbar) {
          scrollbar.destroy();
          scroller.scrollTo = originalScrollTo;
        }
        // scroller.scrollTo = originalScrollTo;
      };
    }
  }, []);

  return (
    <div ref={scrollContainerRef} style={{ height: '100vh', overflow: 'hidden' }} className="your-scroll-container">
      <div ref={contentRef} style={{ minHeight: '100%' }}>
        {children}
      </div>
    </div>
  );
};

export default SmoothScrollWrapper;
