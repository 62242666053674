import React from 'react';

import SmoothScrollWrapper from './../utils/SmoothScrollWrapper';
import Header2 from './ProductPage/Header2';
import Footer1 from './ProductPage/Footer1';
import style from './ErrorPage.module.css';

function ErrorPage() {
  return (
    <>
      <Header2 />
      <SmoothScrollWrapper>
        <div className={style.ErrorPage}>
          <div className={style.text}>
            <div>Ошибка 404</div>
            <div>Такой страницы не существует</div>
          </div>
          {/* <div className={style.text}>Перейти на главную</div> */}
        </div>
        <Footer1 />
      </SmoothScrollWrapper>
    </>
  );
}

export default ErrorPage;
