import img from '../../../images/products/3.png';
export const AnaliticsDPConstant = {
  name: 'Аналитика скрытого непроизводительного времени (СНПВ)',
  img: img,
  text: `Скрытое непроизводительное время (СНПВ) — одна из главных причин увеличения затрат на бурение. Платформа INNERICA автоматически анализирует причины простоев, формирует отчеты и предлагает решения для их устранения. Это позволяет сократить время бурения и снизить затраты`,
  list: [
    `Анализ данных ключевых показателей эффективности для оперативного принятия решений`,
    `Контроль выполнения KPI и анализ работы бригад`,
    `Интеграция с корпоративными системами для автоматического формирования отчетов`,
  ],
  effects: [
    `Снижение затрат за счет устранения простоев`,
    `Улучшение контроля за временем бурения`,
    `Повышение прозрачности процессов`,
  ],
  items: [
    {
      Id: '01',
      Title: `Отчеты по СНПВ`,
      Text: `Наша платформа автоматически формирует отчеты по скрытому непроизводительному времени (СНПВ). Это помогает выявлять причины простоев и повышать эффективность бурения.`,
    },
    {
      Id: '02',
      Title: `Баланс календарного времени`,
      Text: `Анализируйте и оптимизируйте график работ, чтобы минимизировать простои и снизить затраты.`,
    },
    {
      Id: '03',
      Title: `Оперативная аналитика`,
      Text: `Получайте оперативные данные для быстрого анализа и принятия решений. Это особенно важно для сложных участков.`,
    },
    {
      Id: '04',
      Title: `Лучшие практики по КРІ`,
      Text: `Используйте проверенные решения для улучшения ключевых показателей эффективности (КРІ).`,
    },
  ],
};
