import React, { useEffect } from 'react';
import cn from 'classnames';

import style from './RoundButtonTransitArrow.module.css';

type Props = {
  color?: string;
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
};

function RoundButtonTransitArrow({ onClick, className, color = 'white' }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 41 41"
      fill="none"
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'initial' }}
      className={cn(className, style.RoundButtonTransitArrow)}
    >
      <rect width="41" height="41" rx="20.5" fill="white" />
      <path d="M14 27L26.4514 14.5486" stroke="#272727" strokeLinecap="square" />
      <path d="M14.6836 14L26.9993 14" stroke="#272727" strokeLinecap="square" />
      <path d="M27 14L27 25.9673" stroke="#272727" strokeLinecap="square" />
    </svg>
  );
}

export default RoundButtonTransitArrow;
