import React from 'react';

import { useScrollContext } from '../../store/ScrollContext';

import style from './ScrollUpBtn.module.css';

function ScrollUpBtn(): JSX.Element {
  const [isActive, setIsActive] = React.useState(false);
  const scrollUpRef = React.useRef<HTMLDivElement>(null);
  const scrollUpSvgPathRef = React.useRef<SVGPathElement>(null);
  const pathLengthRef = React.useRef(0);

  const offset = 100;

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'test') return;

    const scrollUp = scrollUpRef.current;
    const scrollUpSvgPath = scrollUpSvgPathRef.current;

    if (scrollUpSvgPath) {
      pathLengthRef.current = scrollUpSvgPath.getTotalLength();
      scrollUpSvgPath.style.strokeDasharray = `${pathLengthRef.current} ${pathLengthRef.current}`;
      scrollUpSvgPath.style.transition = 'stroke-dashoffset 20ms';
    }

    const getTop = () => window.pageYOffset || document.documentElement.scrollTop;

    const updateDashoffset = () => {
      const height = document.documentElement.scrollHeight - window.innerHeight;
      const dashoffset = pathLengthRef.current - (getTop() * pathLengthRef.current) / height;

      if (scrollUpSvgPath) {
        scrollUpSvgPath.style.strokeDashoffset = dashoffset.toString();
      }

      if (scrollUp) {
        scrollUp.style.color = dashoffset === 0 ? 'rgb(150, 115, 55)' : '#fff';
      }
    };

    const handleScroll = () => {
      updateDashoffset();

      if (getTop() > offset) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    if (scrollUp) {
      scrollUp.addEventListener('click', handleClick);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollUp) {
        scrollUp.removeEventListener('click', handleClick);
      }
    };
  }, []);

  return (
    <div
      ref={scrollUpRef}
      className={`${style.scrollUp} ${isActive ? style.scrollUpActive : ''}`}
      onClick={handleClick}
    >
      <svg className={style.scrollUpSvg} viewBox="0 0 49 49">
        <path
          ref={scrollUpSvgPathRef}
          className={style.scrollUpSvgPath}
          d=" M 25 1
            a 21 21 0 0 1 0 47
            a 21 21 0 0 1 0 -47"
        />
      </svg>

      <svg
        className={style.arrowIcon}
        width="18"
        height="10"
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17 9L9 1L1 9" stroke="#838383" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}

export default ScrollUpBtn;
