import img from '../../../images/products/6.png';
export const AccountingToolsDPConstant = {
  name: 'Учет наработки инструмента',
  img: img,
  text: `Контроль наработки инструмента — ключевой фактор для предотвращения поломок и простоев`,
  list: [
    `Анализ износа элементов бурового оборудования, прогнозирование сроков замены`,
    `Отслеживание истории использования оборудования на каждом объекте`,
    `Интеграция с системами управления запасами для автоматического заказа новых инструментов`,
  ],
  effects: [
    `Снижение затрат на ремонт и замену инструмента`,
    `Мгновенный доступ к истории оборудования и месту его нахождения`,
    `Повышение надежности процесса бурения`,
  ],
  items: [
    {
      Id: '01',
      Title: `Прогнозирование и профилактика`,
      Text: `Контролируйте наработку инструмента, чтобы избежать поломок и простоев.`,
    },
    {
      Id: '02',
      Title: `Наработка элементов КНБК`,
      Text: `Анализируйте износ элементов бурового оборудования для своевременной замены.`,
    },
    {
      Id: '03',
      Title: `Отработка долот`,
      Text: `Оптимизируйте использование долот, чтобы снизить затраты на бурение.`,
    },
  ],
};
