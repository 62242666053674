import React from 'react';
import cn from 'classnames';
import { Link } from 'react-scroll';
import { useHistory } from 'react-router-dom';

import style from './HeaderItem2.module.css';

// import style from './HeaderItem2.module.css';

type Props = {
  children: React.ReactNode;
  to: string;
  id: number;
  className?: string;
  onClick: (id: number) => void;
};

// function HeaderItem2({ children, to, id, className, onClick }: Props): JSX.Element {
//   const handleClick = (e: React.MouseEvent) => {
//     e.preventDefault();
//     onClick(id);
// debugger
//     if (to) {
//       const target = document.getElementById(to);

//       if (target) {
//         const rect = target.getBoundingClientRect();
//         const offsetTop = rect.top + window.scrollY;
//         window.scrollTo({
//           top: offsetTop,
//           behavior: 'smooth',
//         });
//       }
//     }
//   };

//   return (
//     <li className={cn(style.item, className)}>
//       <a href={`#${to}`} onClick={handleClick}>
//         {children}
//       </a>
//     </li>
//   );
// }

function HeaderItem2({ children, to, id, className, onClick }: Props): JSX.Element {
  const history = useHistory();

  const handleClick = (to: string) => {
    // if (window.location.pathname === '/') {
    //   scroller.scrollTo(to, {
    //     duration: 800,
    //     smooth: true,
    //     offset: 0
    //   });
    // } else {
    history.push({
      pathname: '/',
      state: { scrollTo: to },
    });
    // }
  };

  return (
    // <li className={cn(style.item, className)} onClick={() => handleClick(to)}>
    <div className={style.HeaderItem} onClick={() => handleClick(to)}>
      <li className={className}>{children}</li>
    </div>
  );
}

export default HeaderItem2;
