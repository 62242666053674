import { useState, useEffect, useCallback, useRef } from 'react';

import { debounce } from './debounce';
export const SCREEN_570 = 570;
export const SCREEN_760 = 760;
export const SCREEN_900 = 900;
export const SCREEN_1024 = 1024; // height 600
export const SCREEN_1280 = 1280; // height 900
export const SCREEN_1240 = 1240;
export const SCREEN_1400 = 1400; // height 900
export const SCREEN_1440 = 1440; // container 1100
export const SCREEN_1690 = 1690; // height 960
export const SCREEN_1800 = 1800;
export const SCREEN_1920 = 1920; // height 1080
export const SCREEN_2150 = 2150;

export const useResize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = debounce(event => {
      setWidth(event.target.innerWidth);
    }, 200);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    width,
    isScreen570: width >= SCREEN_570,
    isScreen760: width >= SCREEN_760,
    isScreen900: width >= SCREEN_900,
    isScreen1024: width >= SCREEN_1024,
    isScreen1240: width >= SCREEN_1240,
    isScreen1280: width >= SCREEN_1280,
    isScreen1400: width >= SCREEN_1400,
    isScreen1440: width >= SCREEN_1440,
    isScreen1690: width >= SCREEN_1690,
    isScreen1800: width >= SCREEN_1800,
    isScreen1920: width >= SCREEN_1920,
    isScreen2150: width >= SCREEN_2150,
  };
};

// export const useHover = () => {
//   const [isHovering, setIsHovering] = useState(false);

//   const handleMouseOver = useCallback(() => setIsHovering(true), []);
//   const handleMouseOut = useCallback(() => setIsHovering(false), []);

//   const nodeRef = useRef();

//   const callbackRef = useCallback(
//     (node) => {
//       if (nodeRef.current) {
//         nodeRef.current.removeEventListener('mouseover', handleMouseOver);
//         nodeRef.current.removeEventListener('mouseout', handleMouseOut);
//       }

//       nodeRef.current = node;

//       if (nodeRef.current) {
//         nodeRef.current.addEventListener('mouseover', handleMouseOver);
//         nodeRef.current.addEventListener('mouseout', handleMouseOut);
//       }
//     },
//     [handleMouseOver, handleMouseOut],
//   );

//   return [callbackRef, isHovering];
// };

// export const useOutsideClick = (callback) => {
//   const ref = useRef();
//   const handleClickOutside = (event) => {
//     if (ref.current && !ref.current.contains(event.target)) {
//       callback();
//     }
//   };
//   useEffect(() => {
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);
//   return ref;
// };
