import React from 'react';
import cn from 'classnames';

import NavigationArrow from '../../SVG/NavigationArrow';
import Tag from '../../UIkit/Tag';
import Button from '../../UIkit/Button';
import { StoreContext } from '../../../store';

import style from './Item.module.css';
import { IProduct } from './1MonitoringConstants';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'id'> {
  id: number;
  // className: string;
  name: string;
  item: IProduct;
}
function ItemDP({ item, name, id, ...props }: Props): JSX.Element {
  const { contactUs, video } = React.useContext(StoreContext);
  return (
    <div id="#sectionDP" className={style.sectionDP}>
      {/* <div className={style.detailitem__title} style={{ flexDirection: id % 2 == 0 ? 'row-reverse' : 'row' }}>
        <Tag title="Продукты INNERICA" color="grey" />
        <h2>{item.name}</h2>
      </div> */}
      <div className={cn(style.grid, props.className)}>
        <div className={style.card}>
          <p>{item.text}</p>
          <NavigationArrow className={style.arrow} color="#688AEA" />
        </div>
        <div className={style.card}>
          <ul className={style.list}>
            {item.list.map(el => {
              return <li key={`ItemDP_itemlist__${el}`}>{el}</li>;
            })}
          </ul>
          <NavigationArrow className={style.arrow} color="#688AEA" />
        </div>
        <div className={style.class7}>
          {item.items.map(el => {
            return (
              <div className={style.boxitem} key={`ItemDP_${el.Id}`}>
                <Tag className={style.boxitem__Id} title={el.Id} color="grey" />
                <div className={style.boxitem__Title}>{el.Title}</div>
                <div className={style.boxitem__Text}>{el.Text}</div>
              </div>
            );
          })}
        </div>
        <div className={style.class2}>
          <div className={cn(style.card, style.active)}>
            <Tag className={style.card__tag} title={'Эффекты'} color="second" type="filled" />
            <ul className={style.list}>
              {item.effects.map(el => {
                return <li key={`ItemDP_effects__${el}`}>{el}</li>;
              })}
            </ul>
          </div>
          <div>{item.img && <img src={item.img} alt={item.name} />}</div>
        </div>
        {/* <div className={style.btnbox}>
          <Button className={style.btn} onClick={contactUs.open} variant="filled" round>
            Заказать бесплатную демонстрацию
          </Button>
        </div> */}
      </div>
    </div>
  );
}

export default ItemDP;
