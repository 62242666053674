import React from 'react';

import client1 from '../../images/ClientsLogo1.png';
import client2 from '../../images/ClientsLogo2.png';
import client3 from '../../images/ClientsLogo3.png';
import client4 from '../../images/ClientsLogo4.png';
import client5 from '../../images/ClientsLogo5.png';
import client6 from '../../images/ClientsLogo6.png';
import client7 from '../../images/ClientsLogo7.png';
import client8 from '../../images/ClientsLogo8.png';
import client9 from '../../images/ClientsLogo9.png';
import client10 from '../../images/ClientsLogo10.png';
import client11 from '../../images/ClientsLogo11.png';
import client12 from '../../images/ClientsLogo12.png';
import Tag from '../UIkit/Tag';

import style from './Clients1.module.css';

function Clients1(): JSX.Element {
  return (
    <article className={style.container} id="clients">
      <div className={style.header}>
        <Tag className={style.header__tag} title={'Клиенты'} color="grey" />
        <h2 className={style.header_title}>
          <span>Наши решения</span> выбирают
        </h2>
      </div>
      <div className={style.clients}>
        <img src={client1} title="Роснефть" alt="Роснефть" />
        <img src={client2} title="ТаграС" alt="ТаграС" />
        <img src={client3} title="Белнефтехим" alt="Белнефтехим" />
        <img src={client4} title="Гомельоблнефтепродукт" alt="Гомельоблнефтепродукт" />
        <img src={client5} title="Нафтан" alt="Нафтан" />
        <img src={client6} title="Белорусский Нефтяной Торговый Дом" alt="Белорусский Нефтяной Торговый Дом" />
        <img src={client7} title="Tatneft" alt="Tatneft" />
        <img src={client8} title="Дружба" alt="Дружба" />
        <img src={client9} title="Лукойл" alt="Лукойл" />
        <img src={client10} title="Юкола" alt="Юкола" />
        <img src={client11} title="Беларуская нефтяная компания" alt="Беларуская нефтяная компания" />
        <img src={client12} title="Газпром" alt="Газпром" />
      </div>
    </article>
  );
}

export default Clients1;
