import React, { useState, useEffect, useRef } from 'react';
import { animateScroll } from 'react-scroll';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
// import LoginButton from '../Buttons/LoginButton';
import { Link } from 'react-router-dom';

import { StoreContext } from '../../../store';
import DropdownMenu from '../../../components/UIkit/DropdownMenu';
import { DigitalPlatformSections1 } from '../../../components/DigitalPlatform1/constants';

import style from './Header2.module.css';
import HeaderItem2 from './HeaderItem2';

function Header2(): JSX.Element {
  const [isOpen, setOpen] = useState(false);

  function scrollToTop(): void {
    animateScroll.scrollToTop();
  }

  function hideMenu(): void {
    setOpen(false);
  }

  const { contactUs } = React.useContext(StoreContext);
  // React.useContext(StoreContext);

  function handleToggleClick(): void {
    setOpen(!isOpen);
  }

  const hostname = window.location.hostname;
  // const isBY = hostname.endsWith('.by');
  // const menuItems = [
  //   { to: 'advantages', label: 'Преимущества' },
  //   { to: 'product', label: 'Продукт' },
  //   { to: 'clients', label: 'Клиенты', isHidden: isBY },
  //   { to: 'calculator', label: 'Калькулятор', isHidden: isBY },
  //   { to: 'equipments', label: 'Оборудование' },
  //   { to: 'review', label: 'Отзывы', isHidden: isBY },
  // ];
  const menuItems = [
    { to: 'platform1', label: 'Платформа', id: 1 },
    {
      id: 0,
      label: 'Продукты',
      to: 'product',
      dropdownItems: DigitalPlatformSections1.map(section => ({
        label: section.Name,
        to: `${section.EnName}`,
        id: section.Id,
      })),
    },
    { to: 'company', label: 'О компании', id: 3 },
    { to: 'clients1', label: 'Клиенты', id: 7 },
    { to: 'review1', label: 'Отзывы', id: 9 },
    { to: 'blog1', label: 'Блог', id: 10 },
  ];

  return (
    <header className={cn(style.header, style.small)}>
      <nav className={style.nav}>
        <Link to={`/`}>
          <button className={style.logo} aria-label="logo" />
        </Link>
        <ul className={style.ulmain}>
          <OutsideClickHandler onOutsideClick={hideMenu}>
            <div className={cn(style.itemsContainer, isOpen && style.mobileMenu)}>
              {menuItems.map(item => {
                // if (item.isHidden) return;
                if (item.dropdownItems) {
                  return (
                    <DropdownMenu
                      key={item.to}
                      title={item.label}
                      items={item.dropdownItems}
                      className={cn(style.item, isOpen && style.itemAppear)}
                    />
                  );
                }
                return (
                  <HeaderItem2
                    key={item.to}
                    onClick={hideMenu}
                    id={item.id}
                    to={item.to}
                    className={cn(style.item, isOpen && style.itemAppear)}
                  >
                    {item.label}
                  </HeaderItem2>
                );
              })}
            </div>
          </OutsideClickHandler>
        </ul>
        <div className={style.contacts}>
          <div className={style.phone}>
            <a href="tel:+7(985)305-05-41">+7 (985) 305-05-41</a>
          </div>
          {/* <Tag
            className={style.btnphone}
            title={'Перезвонить'}
            color="second"
            as={'button'}
            type="filled"
            onClick={contactUs.open}
          /> */}
          {/* <div className={style.btnphone}>
              <button</button>
            </div> */}
        </div>
        <button className={style.menu} onClick={handleToggleClick} aria-label="toggle" type="button">
          Меню
        </button>
      </nav>
    </header>
  );
}

export default Header2;
