import React from 'react';
import cn from 'classnames';

import Button from '../UIkit/Button';

import style from './SubmitButton.module.css';

type Props = {
  title: string;
  className?: string;
  small?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

function SubmitButton({ title, className, small, onClick = () => {} }: Props): JSX.Element {
  return (
    <Button
      variant="filled"
      className={cn(style.button, className, small && style.small)}
      type="submit"
      onClick={onClick}
    >
      {title}
    </Button>
  );
}

export default SubmitButton;
