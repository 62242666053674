import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import Tag from '../../components/UIkit/Tag';
import NavigationArrow from '../../components/SVG/NavigationArrow';
import { DigitalPlatformSections1 } from '../../components/DigitalPlatform1/constants';
import { IProduct } from '../../components/DigitalPlatform/items/1MonitoringConstants';
import Button from '../../components/UIkit/Button';
import { StoreContext } from '../../store';
import SmoothScrollWrapper from '../../utils/SmoothScrollWrapper';
import ErrorPage from '../ErrorPage';
// import style from './ProductPage.module.css';
import ItemDP from '../../components/DigitalPlatform/items/ItemDP';
import MonitoringDP from '../../components/DigitalPlatform/items/1Monitoring';
import AnaliticsDP from '../../components/DigitalPlatform/items/2Analitics';
import RaportDP from '../../components/DigitalPlatform/items/3Raport';
import AccountingToolsDP from '../../components/DigitalPlatform/items/5AccountingTools';
import PlanDP from '../../components/DigitalPlatform/items/6Plan';
import WellDP from '../../components/DigitalPlatform/items/7Well';
import CostsDP from '../../components/DigitalPlatform/items/4Costs';
import TrajectoryDP from '../../components/DigitalPlatform/items/8Trajectory';

import Header2 from './Header2';
import Footer1 from './Footer1';
import FooterBy1 from './Footer1/FooterBy1';

function ProductPage() {
  const hostname = window.location.hostname;
  const isBY = hostname.endsWith('.by');
  const { contactUs, video } = React.useContext(StoreContext);
  const { enName } = useParams<{ enName: string }>();
  const [curProduct, setCurProduct] = useState(DigitalPlatformSections1.find(item => item.EnName === enName));

  useEffect(() => {
    setCurProduct(DigitalPlatformSections1.find(item => item.EnName === enName));
  }, [enName]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [curProduct]);

  if (!curProduct) {
    return <ErrorPage />;
  }
  const mas = [MonitoringDP, TrajectoryDP, AnaliticsDP, RaportDP, CostsDP, AccountingToolsDP, PlanDP, WellDP];
  const isValidId = curProduct.Id >= 0 && curProduct.Id < mas.length;
  // const InfoComponent = mas[curProduct.Id];
  const InfoComponent = isValidId ? mas[curProduct.Id] : null;

  return (
    <>
      <Header2 />
      <SmoothScrollWrapper>
        {InfoComponent && <InfoComponent item={curProduct.Product} name={curProduct.Name} id={curProduct.Id} />}
        {/* <ItemDP
          item={curProduct.Product}
          name={curProduct.Name}
          id={curProduct.Id}
        /> */}
        {isBY ? <FooterBy1 /> : <Footer1 />}
      </SmoothScrollWrapper>
    </>
  );
}

export default ProductPage;
