import React, { forwardRef } from 'react';
import { animateScroll, scroller, Link } from 'react-scroll';

import { FooterForm1 } from '../Form1';
import snTelegram from '../../images/sn_icon_telegram.svg';
import snWhatsup from '../../images/sn_icon_whatsapp.svg';
import logo from '../../images/HeaderLogo1.svg';
// import logo from '../../images/HeaderLogo.svg';
// import fingers from '../../images/fingers.png';
// import { FooterAnimation } from '../SVG';
import FormContactUs from '../FormContactUs';

import style from './Footer1.module.css';

// interface Props {}
const Footer1 = forwardRef<HTMLElement>((props, ref) => {
  function scrollToTop(): void {
    // animateScroll.scrollToTop();
    scroller.scrollTo('main1', {
      duration: 800,
      smooth: true,
      offset: 0,
    });
  }

  const menuItems = [
    { to: 'platform1', label: 'Платформа', id: 1 },
    { to: 'company', label: 'О компании', id: 3 },
    { to: 'clients1', label: 'Клиенты', id: 7 },
    { to: 'review1', label: 'Отзывы', id: 9 },
  ];
  const handleClick = (to: string) => {
    scroller.scrollTo(to, {
      duration: 800,
      smooth: true,
      offset: 0,
    });
    // onClick(id);
  };
  return (
    <footer ref={ref} className={style.footer}>
      {/* <div className={style.animation}> */}
      {/* <FooterAnimation /> */}
      {/* </div> */}
      {/* <FooterForm1 className={style.form} /> */}
      <FormContactUs className={style.form} />
      <div className={style.container}>
        <ul className={style.list}>
          <li className={style.logoContainer}>
            <img className={style.logo} src={logo} alt="logo" onClick={scrollToTop} />
            {/* <button className={style.logo} onClick={scrollToTop} aria-label="logo" /> */}
          </li>
          <li>
            <div className={style.item}>
              <p className={style.title}>Контакты</p>
              <a href="tel:+7(985)305-05-41">+7 (985) 305-05-41</a>
              <a href="mailto:office@innerica.ru">office@innerica.ru</a>
              <div className={style.icons}>
                <img src={snTelegram} alt="telegram" />
                <img src={snWhatsup} alt="whatsup" />
              </div>
            </div>
            <div className={style.item}>
              <p className={style.title}>Офис</p>
              <div>г. Москва, Можайское шоссе, д.29</div>
            </div>
          </li>
          <li>
            <p className={style.title}>Навигация</p>
            <ul>
              {menuItems.map(item => {
                return (
                  <li key={`Footer_${item.to}`} onClick={() => handleClick(item.to)} style={{ cursor: 'pointer' }}>
                    {item.label}
                  </li>
                );
              })}
              {/* <li>Платформа</li>
              <li>О продукте</li>
              <li>Клиенты</li>
              <li>Отзывы</li> */}
            </ul>
          </li>
          {/* <li className={style.fingersContainer}>
            <a href="https://fingers-media.com/" className={style.fingers} target="_blank" rel="noopener noreferrer">
              <img src={fingers} alt="Fingers Media" title="Fingers Media" /> 
              Дизайн и разработка
            </a>
          </li> */}
        </ul>
      </div>
    </footer>
  );
});
Footer1.displayName = 'Footer1';

export default Footer1;
