import React from 'react';
import cn from 'classnames';

import NavigationArrow from '../SVG/NavigationArrow';

import style from './Tag.module.css';

type Props = {
  title: string;
  withHover?: boolean;
  as?: React.ElementType;
  onClick?: () => void;
  className?: string;
  type?: 'filled' | 'plain';
  color?: 'main' | 'white' | 'grey' | 'second';
};

function Tag({ title, className, as: Element = 'div', withHover, type = 'plain', color = 'main' }: Props): JSX.Element {
  const buttonClass = cn(
    style.tag,
    className,
    withHover && style.withHover,
    {
      [style.filled]: type === 'filled',
      [style.plain]: type === 'plain',
      [style.main]: color === 'main',
      [style.white]: color === 'white',
      [style.grey]: color === 'grey',
      [style.second]: color === 'second',
    },
    className,
  );
  return <Element className={buttonClass}>{title}</Element>;
}

export default Tag;
