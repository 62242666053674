// import img from '../../../images/AdvantagesClients.png';
export const WellDPConstant = {
  name: 'Электронное дело скважины',
  img: null,
  text: `Электронное дело скважины — это единая база данных, где хранится вся информация по скважине. Платформа INNERICA позволяет извлекать накопленный опыт, анализировать данные и передавать их заказчику в удобном формате`,
  list: [
    `Использование облачных технологий для хранения и обработки данных`,
    `Возможность интеграции с корпоративными системами Заказчика для автоматического обновления данных`,
    `Поддержка работы с большими объемами данных (Big Data)`,
  ],
  effects: [
    `Упрощение доступа к данным`,
    `Повышение прозрачности процессов`,
    `Улучшение качества анализа и планирования`,
  ],
  items: [
    {
      Id: '01',
      Title: `Извлечение накопленного опыта`,
      Text: `Используйте данные из электронного дела скважины для улучшения текущих процессов.`,
    },
    {
      Id: '02',
      Title: `Хранение и передача данных`,
      Text: `Все данные хранятся в единой системе и могут быть переданы заказчику в удобном формате.`,
    },
  ],
};
