import React, { forwardRef, useRef, useState } from 'react';
import cn from 'classnames';

import style from './Video2Btn.module.css';

interface Props {
  togglePlay: any;
}

function Video2Btn({ togglePlay }: Props): JSX.Element {
  return (
    <button className={style.btn} onClick={togglePlay}>
      <div className={style.playButton}>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="29" viewBox="0 0 17 29" fill="none">
          <path
            d="M0.138515 1.98799V26.8942C0.139149 27.1464 0.194885 27.3935 0.299723 27.6091C0.404562 27.8247 0.554531 28.0005 0.733492 28.1176C0.912454 28.2348 1.11363 28.2888 1.31536 28.2739C1.51709 28.259 1.71174 28.1757 1.87836 28.033L16.2939 15.5799C16.8916 15.0638 16.8916 13.8212 16.2939 13.3037L1.87836 0.850604C1.71209 0.706459 1.51734 0.621929 1.31527 0.606198C1.1132 0.590467 0.911546 0.644137 0.732206 0.761376C0.552866 0.878616 0.402704 1.05494 0.298035 1.27119C0.193367 1.48745 0.138197 1.73536 0.138515 1.98799Z"
            fill="white"
          />
        </svg>
      </div>
      <div className={style.text}>Видео о компании</div>
    </button>
  );
}

export default Video2Btn;
