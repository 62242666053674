import img1 from '../../images/AdvantagesConvenience.png';
export const CUSTOMERFOCUS = {
  title: 'Клиентоориентированность',
  items: [
    {
      id: 1,
      title: 'Гибкая цена',
      description: 'Платите только за скважины, по которым поступают данные',
    },
    {
      id: 10,
      img: img1,
    },
    {
      id: 2,
      title: 'Адаптируемость',
      description: 'Возможность доработки системы под индивидуальные требования',
    },
    {
      id: 20,
      img: img1,
    },
    {
      id: 30,
      img: img1,
    },
    {
      id: 3,
      title: 'Облачное размещение',
      description: 'Пробную версию можно развернуть как в облаке, так и на собственном сервере',
    },
    {
      id: 40,
      img: img1,
    },
    {
      id: 4,
      title: 'Профессионализм',
      description: 'Наши специалисты ответят на все вопросы',
    },
  ],
};
