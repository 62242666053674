import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// import Header from './components/Header';
// import Footer from './components/Footer';
import HeaderBy from './components/Header/HeaderBy';
// import FooterBy from './components/Footer/FooterBy';
import PopupOpener from './components/Modals/PopupOpener';
import AlertCookie from './components/Alert/AlertCookie';
import DynamicScriptLoader from './components/Alert/DynamicScriptLoader';
import StoreProvider from './store';
import HomePage from './pages/HomePage';
import FooterBy1 from './components/Footer1/FooterBy1';
import Footer1 from './components/Footer1';
import Header1 from './components/Header1';
import HeaderBy1 from './components/Header1/HeaderBy1';
import { ScrollProvider } from './store/ScrollContext';
import Header2 from './components/Header2';
import HeaderBy2 from './components/Header2/HeaderBy2';
import ProductPage from './pages/ProductPage/ProductPage';
import ArticlePage from './pages/ArticlePage';

function App(): JSX.Element {
  const hostname = window.location.hostname;
  const isBY = hostname.endsWith('.by');
  return (
    <StoreProvider>
      {/* <ScrollProvider> */}
      <Router>
        <PopupOpener />
        {/* {isBY ? <HeaderBy1 /> : <Header1 />} */}
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/product/:enName">
            <ProductPage />
          </Route>
          <Route path="/article/:enName">
            <ArticlePage />
          </Route>
          {/* <Route path="/licence">
          <LicencePage />
        </Route> */}
        </Switch>
        {/* {isBY ? <FooterBy1 /> : <Footer1 />} */}
        <AlertCookie />
        <DynamicScriptLoader />
      </Router>
      {/* </ScrollProvider> */}
    </StoreProvider>
  );
}

export default App;
