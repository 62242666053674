import React from 'react';
import cn from 'classnames';

import style from './Button.module.css';

type Props = {
  children: string;
  variant?: 'filled' | 'plain' | 'outline';
  color?: 'main' | 'white';
  className?: string;
  round?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
} & React.ComponentPropsWithoutRef<'button'>;

function Button({
  onClick,
  children,
  className,
  variant = 'plain',
  color,
  round = false,
  ...props
}: Props): JSX.Element {
  const buttonClass = cn(
    style.button,
    {
      [style.filled]: variant === 'filled',
      [style.plain]: variant === 'plain',
      [style.main]: color === 'main',
      [style.white]: color === 'white',
      [style.round]: round,
    },
    className,
  );

  return (
    <button className={buttonClass} onClick={onClick} {...props}>
      {children}
    </button>
  );
}

export default Button;
