import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import Tag from '../components/UIkit/Tag';
import NavigationArrow from '../components/SVG/NavigationArrow';
import { DigitalPlatformSections1 } from '../components/DigitalPlatform1/constants';
import { IProduct } from '../components/DigitalPlatform/items/1MonitoringConstants';
import Button from '../components/UIkit/Button';
import { StoreContext } from '../store';
import SmoothScrollWrapper from '../utils/SmoothScrollWrapper';

import ErrorPage from './ErrorPage';
import Header2 from './ProductPage/Header2';
import Footer1 from './ProductPage/Footer1';
import FooterBy1 from './ProductPage/Footer1/FooterBy1';

function ArticlePage() {
  const hostname = window.location.hostname;
  const isBY = hostname.endsWith('.by');
  const { contactUs, video } = React.useContext(StoreContext);
  const { enName } = useParams<{ enName: string }>();
  const [curProduct, setCurProduct] = useState(DigitalPlatformSections1.find(item => item.EnName === enName));

  return <ErrorPage />;
  // if (!curProduct) {
  //   return <ErrorPage />;
  // }
  // return (
  //   <>
  //     <Header2 />
  //     <SmoothScrollWrapper>
  //       {isBY ? <FooterBy1 /> : <Footer1 />}
  //     </SmoothScrollWrapper>
  //   </>
  // );
}

export default ArticlePage;
