import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

import data from '../data.json';
import DigitalPlatform from '../components/DigitalPlatform';
import Facilities from '../components/Facilities';
import ScrollUpBtn from '../components/ScrollUpBtn';
import ProductFeatures from '../components/ProductFeatures';
import Video2 from '../components/Video2';
import CustomerFocus from '../components/CustomerFocus';
import Clients1 from '../components/Clients1';
import Equipment1 from '../components/Equipment1';
import Review1 from '../components/Review1';
import Monitoring from '../components/Monitoring';
import Main1 from '../components/Main1';
import InfoBlock1 from '../components/InfoBlock1';
import { useScrollHandler } from '../utils/useScrollHandler';
import Footer1 from '../components/Footer1';
import FooterBy1 from '../components/Footer1/FooterBy1';
import { useScrollContext } from '../store/ScrollContext';
import SmoothScrollLocomotive from '../utils/SmoothScrollLocomotive';
import Main3 from '../components/Main1/Main3';
import Blog from '../components/Blog';
import { useSmoothScroll } from '../utils/CustomSmoothScroll';
import Header2 from '../components/Header2';
import SmoothScrollWrapper from '../utils/SmoothScrollWrapper';
import DigitalPlatform1 from '../components/DigitalPlatform1';
import HeaderBy2 from '../components/Header2/HeaderBy2';
import Blog1 from '../components/Blog1';

type LocationState = {
  scrollTo?: string;
};

function HomePage(): JSX.Element {
  const hostname = window.location.hostname;
  const isBY = hostname.endsWith('.by');

  //note: для многостраничной навигации
  const location = useLocation<LocationState>();
  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      scroller.scrollTo(location.state.scrollTo, {
        duration: 800,
        smooth: true,
        offset: 0,
      });
    }
  }, [location]);

  // useSmoothScroll();
  // return (
  //     <>
  //       <ScrollUpBtn />
  //       {/* <Main1 /> */}
  //       <Main3 />
  //       <DigitalPlatform />
  //       <Video2 />
  //       <>
  //         <CustomerFocus />
  //         {/* {!isBY && <Clients1 />} */}
  //       </>
  //       {/* <Equipment1 equipment={data.equipment} /> */}
  //       {!isBY && <Review1 />}
  //       <Blog />
  //       {isBY ? <FooterBy1 /> : <Footer1 />}
  //     </>
  // );
  // return (
  //   <SmoothScrollLocomotive>
  //     <>
  //       {/* <Header2 /> */}
  //       <ScrollUpBtn />
  //       {/* <Main1 /> */}
  //       <Main3 />
  //       <DigitalPlatform />
  //       <Video2 />
  //       <>
  //         <CustomerFocus />
  //         {/* {!isBY && <Clients1 />} */}
  //       </>
  //       {/* <Equipment1 equipment={data.equipment} /> */}
  //       {!isBY && <Review1 />}
  //       <Blog />
  //       {isBY ? <FooterBy1 /> : <Footer1 />}
  //     </>
  //   </SmoothScrollLocomotive>
  // );
  return (
    <>
      {isBY ? <HeaderBy2 /> : <Header2 />}
      <SmoothScrollWrapper>
        <>
          {/* <Header2 /> */}
          <ScrollUpBtn />
          {/* <Main1 /> */}
          <Main3 />
          {/* <DigitalPlatform /> */}
          <DigitalPlatform1 />
          <Video2 />
          <>
            <CustomerFocus />
            {/* {!isBY && <Clients1 />} */}
          </>
          {!isBY && <Review1 />}
          {/* <Blog /> */}
          <Blog1 />
          {isBY ? <FooterBy1 /> : <Footer1 />}
        </>
      </SmoothScrollWrapper>
    </>
  );
}

export default HomePage;
