import React from 'react';
import cn from 'classnames';

import TransitArrow from '../SVG/TransitArrow';

import style from './NavigationButton.module.css';

type Props = {
  title: string;
  href?: string;
  onClick?: () => void;
  className?: string;
  small?: boolean;
  as?: React.ElementType;
  withArrow?: boolean;
  variant?: 'outline' | 'plain';
  color?: 'main' | 'white';
};

function NavigationButton({
  title,
  className,
  onClick,
  withArrow = true,
  variant = 'plain',
  color = 'main',
  as: Tag = 'button',
  href,
}: Props): JSX.Element {
  const linkProps = href ? { href, target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <Tag
      className={cn(
        style.button,
        {
          [style.outline]: variant === 'outline',
          [style.plain]: variant === 'plain',
          [style.main]: color === 'main',
          [style.white]: color === 'white',
        },
        className,
      )}
      onClick={onClick && onClick}
      {...linkProps}
    >
      {title}
      {withArrow && <TransitArrow className={style.arrow} color="#4455A1" width={8} height={8} strokeWidth="4" />}
    </Tag>
  );
}

export default NavigationButton;
