import React from 'react';
import cn from 'classnames';

type Props = {
  color: string;
  className?: string;
};

function NavigationArrow({ color, className }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="8"
      viewBox="0 0 34 8"
      fill="none"
      className={cn(className)}
    >
      <path
        d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM33.3536 4.35356C33.5488 4.15829 33.5488 3.84171 33.3536 3.64645L30.1716 0.464469C29.9763 0.269206 29.6597 0.269206 29.4645 0.464469C29.2692 0.659731 29.2692 0.976313 29.4645 1.17158L32.2929 4L29.4645 6.82843C29.2692 7.02369 29.2692 7.34027 29.4645 7.53554C29.6597 7.7308 29.9763 7.7308 30.1716 7.53554L33.3536 4.35356ZM1 4.5L33 4.5L33 3.5L1 3.5L1 4.5Z"
        fill={color}
        fillOpacity="0.9"
      />
    </svg>
  );
}

export default NavigationArrow;
