import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { StoreContext } from '../../store';
import Button from '../UIkit/Button';
import RoundButtonArrowMain from '../UIkit/RoundButtonArrowMain';

import style from './Main3.module.css';

// interface Props {}
const Main3 = forwardRef<HTMLElement>((props, ref) => {
  const { contactUs, video } = React.useContext(StoreContext);

  const [containerHeight, setContainerHeight] = useState(0);
  const imgRef = useRef<HTMLImageElement>(null);

  const handleImageLoad = () => {
    if (imgRef.current) {
      setContainerHeight(imgRef.current.clientHeight);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (imgRef.current) {
        setContainerHeight(imgRef.current.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <article ref={ref} className={style.container} id="main1">
      <div className={style.topContainer}></div>
      <h1>Цифровая платформа для строительства скважин</h1>
      <div
        className={style.bottomContainer}
        data-scroll
        data-scroll-class="visible"
        data-scroll-repeat="true"
        id="headerTrigger"
      >
        <Button className={style.detailbtn} onClick={contactUs.open} variant="plain" color="white" round>
          Свяжитесь с нами
        </Button>
        {/* <RoundButtonArrowMain className={style.iconbtn} onClick={contactUs.open} /> */}
      </div>
    </article>
  );
});
Main3.displayName = 'Main3';

export default Main3;
