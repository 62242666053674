import React, { forwardRef } from 'react';
import cn from 'classnames';

import { StoreContext } from '../../store';
import arrow from '../../images/DigitalPlatformArrow.svg';
import ItemDP from '../DigitalPlatform/items/ItemDP';
import { MonitoringDPConstant } from '../DigitalPlatform/items/1MonitoringConstants';
import { AnaliticsDPConstant } from '../DigitalPlatform/items/2AnaliticsConstants';
import { RaportDPConstant } from '../DigitalPlatform/items/3RaportConstants';
import { AccountingToolsDPConstant } from '../DigitalPlatform/items/5AccountingToolsConstants';
import { PlanDPConstant } from '../DigitalPlatform/items/6PlanConstants';
import { WellDPConstant } from '../DigitalPlatform/items/7WellConstants';
import { CostsDPConstant } from '../DigitalPlatform/items/4CostsConstants';
import { TrajectoryDPConstant } from '../DigitalPlatform/items/8TrajectoryConstants';

import { DigitalPlatformSections1, DigitalPlatformTabs1, ISection } from './constants';
import style from './DigitalPlatform1.module.css';

// interface Props {}
const DigitalPlatform1 = forwardRef<HTMLElement>((props, ref) => {
  const { contactUs, video } = React.useContext(StoreContext);
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeSection, setActiveSection] = React.useState(DigitalPlatformTabs1[activeTab].Items[0]);
  // const [activeSection, setActiveSection] = React.useState<ISection | null>(null);
  const onTabChange = (id: number) => {
    setActiveTab(id);
    setActiveSection(DigitalPlatformTabs1[id].Items[0]);
  };
  const onClickSectionItem = (id: number) => {};
  // console.log(DigitalPlatformTabs1[activeTab].Items[activeSection.Id].Items);

  const mas = [
    MonitoringDPConstant,
    TrajectoryDPConstant,
    AnaliticsDPConstant,
    RaportDPConstant,
    CostsDPConstant,
    AccountingToolsDPConstant,
    PlanDPConstant,
    WellDPConstant,
  ];
  return (
    <article ref={ref} className={style.container} id="platform1">
      {/* <h2>ЦИФРОВАЯ ПЛАТФОРМА INNERICA</h2> */}
      <h2>Программные продукты INNERICA</h2>
      <div>
        <div className={style.tabs}>
          {DigitalPlatformTabs1.map(tab => (
            <button
              key={`DigitalPlatform_Tabs${tab.Name}`}
              onClick={e => onTabChange(tab.Id)}
              className={tab.Id === activeTab ? style.activeTab : ''}
            >
              {tab.Name}
            </button>
          ))}
        </div>
        <div className={style.sections}>
          <div className={style.sectionsLeft}>
            <h3>Продукты INNERICA</h3>
            {DigitalPlatformTabs1[activeTab].Items.map(section => (
              <div
                className={cn(
                  style.sectionWrapper,
                  activeSection && section.Id === activeSection.Id && style.sectionWrapperActive,
                )}
                key={`DigitalPlatform_section${section.Name}`}
              >
                <button onClick={() => setActiveSection(section)}>{section.Name}</button>
                <img src={true ? arrow : arrow} alt="arrow" width={22} height={22} />
                {/* {activeSection === section.Id && section.Items.length > 0 && (
                <ul>
                  {section.Items.map((item) => (
                    <li key={item.Id}>{item.Name}</li>
                  ))}
                </ul>
              )} */}
              </div>
            ))}
          </div>
          <div className={style.sectionsRight}>
            {/* {activeSection !== null &&
              DigitalPlatformTabs1[activeTab].Items[activeSection].Items.map(sectionItem => {
                if (sectionItem.Url) {
                  // return sectionItem.Url
                  return (
                    <img
                      key={`DigitalPlatform_img_${sectionItem.Id}`}
                      className={style.sectionsRightImg}
                      src={sectionItem.Url}
                      alt={sectionItem.Name}
                    />
                  );
                }
                return (
                  <div className={style.sectionsRightWrapper} key={`DigitalPlatform_sectionItem${sectionItem.Id}`}>
                    <button onClick={() => onClickSectionItem(sectionItem.Id)}> {sectionItem.Name}</button>
                    <img src={true ? arrow : arrow} alt="arrow" width={18} height={18} />
                  </div>
                );
              })} */}
            {activeSection && (
              <ItemDP
                item={mas[activeSection.Id]}
                name={activeSection.Name}
                id={activeSection.Id}
                className={style.grid2}
              />
            )}
          </div>
        </div>
      </div>
    </article>
  );
});
DigitalPlatform1.displayName = 'DigitalPlatform1';
export default React.memo(DigitalPlatform1);
