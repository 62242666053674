import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import cn from 'classnames';

import CloseButton from '../Buttons/CloseButton';
import Input from '../Inputs/Input';
import Dropdown from '../Inputs/Dropdown';
import SubmitButton from '../Buttons/SubmitButton';
import { StoreContext } from '../../store';

import Modal from './Modal';
import Schema from './ContactUsSchema';
import style from './ContactUs.module.css';

type Item = {
  value: string;
  id: number;
};

type MyFormValues = {
  name: string;
  email: string;
  companyName: string;
  equipment: string;
  companyType: Item | null;
  wellNumber?: Item | null;
  platformPlace?: Item | null;
  needHelp?: Item | null;
};

const companyTypeItems = [
  { value: 'Нефтяная / Газовая', id: 1 },
  { value: 'Буровая / Сервисная', id: 2 },
  { value: 'Производитель оборудования', id: 3 },
  { value: 'Другая', id: 4 },
];

const wellNumberItems = [{ value: 'меньше 5', id: 1 }, { value: 'от 5 до 15', id: 2 }, { value: 'больше 15', id: 3 }];

const platformPlaceItems = [
  { value: 'В облаке', id: 1 },
  { value: 'На сервере моей компании', id: 2 },
  { value: 'Пока не решил(-а)', id: 3 },
];

const needHelpItems = [
  { value: 'Да. Нужно полное сопровождение', id: 1 },
  { value: 'Возможно понадобится пару консультаций', id: 2 },
  { value: 'Нет. Постараюсь все сделать сам', id: 3 },
  { value: 'Не знаю', id: 4 },
];

type T = Window & typeof globalThis;
interface MyWindow extends T {
  ['Infinity']: any;
  ['NaN']: any;
  env: {
    REACT_APP_API_URL: string;
  };
}

function ContactUs(): JSX.Element {
  const { contactUs, success, failed, preloader } = React.useContext(StoreContext);

  const [isOptionOpen, setOptionOpen] = useState(false);

  async function sendMail(values: any) {
    try {
      preloader.open();
      await fetch(`${(window as MyWindow).env.REACT_APP_API_URL}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      preloader.close();
      success.open();
    } catch (error) {
      preloader.close();
      failed.open();
    }
  }

  const handleSubmit = (values: MyFormValues): void => {
    const postValues: any = {
      name: values.name,
      company: values.companyName,
      email: values.email,
      // company_type: values.companyType ? values.companyType.value : '',
      equipment: values.equipment,
      // well_per_month: values.wellNumber ? values.wellNumber.value : '',
      // place: values.platformPlace ? values.platformPlace.value : '',
      // need_help: values.needHelp ? values.needHelp.value : '',
    };
    if (values.companyType) postValues['company_type'] = values.companyType.value;
    if (values.wellNumber) postValues['need_help'] = values.wellNumber.value;
    if (values.platformPlace) postValues['place'] = values.platformPlace.value;
    if (values.needHelp) postValues['need_help'] = values.needHelp.value;
    sendMail(postValues);
    contactUs.close();
  };

  function handleOptionOpen(): void {
    setOptionOpen(true);
  }

  function closeOption(): void {
    setOptionOpen(false);
  }

  function renderForm(): JSX.Element {
    return (
      <Form className={cn(style.form, isOptionOpen && style.optionForm)}>
        <Field type="text" name="name" component={Input} placeholder={'Как Вас зовут?'} small />
        <Field type="text" name="email" component={Input} placeholder={'Ваш e-mail'} small />
        <Field type="text" name="companyName" component={Input} placeholder={'Название Вашей компании'} small />
        <Field type="text" name="equipment" component={Input} placeholder={'Ваше оборудование'} small />
        <Field
          name="companyType"
          component={Dropdown}
          placeholder={'Тип Вашей компании'}
          items={companyTypeItems}
          handleOptionOpen={handleOptionOpen}
          closeOption={closeOption}
        />
        {isOptionOpen && (
          <div className={style.optionsContainer}>
            <label htmlFor="wellNumber"> Сколько скважин в месяц Вы бурите? </label>
            <label htmlFor="platformPlace" className={style.placeLabel}>
              {' '}
              Где развернем платформу?
            </label>
            <label htmlFor="needHelp" className={style.helpLabel}>
              {' '}
              Нужна ли помощь в установке?{' '}
            </label>
            <Field
              id="wellNumber"
              name="wellNumber"
              component={Dropdown}
              items={wellNumberItems}
              className={style.numberInput}
            />
            <Field
              id="platformPlace"
              name="platformPlace"
              component={Dropdown}
              items={platformPlaceItems}
              className={style.placeInput}
            />
            <Field
              id="needHelp"
              name="needHelp"
              component={Dropdown}
              items={needHelpItems}
              className={style.helpInput}
            />
          </div>
        )}
        <SubmitButton title="Отправить" className={style.button} small />
      </Form>
    );
  }

  return (
    <Modal contact>
      <CloseButton close={contactUs.close} />
      <h3 className={style.title}>
        Как мы можем помочь Вам? <br /> Свяжитесь с нами в любое время
      </h3>
      <Formik
        initialValues={{
          name: '',
          email: '',
          companyName: '',
          equipment: '',
          companyType: null,
          wellNumber: null,
          platformPlace: null,
          needHelp: null,
        }}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    </Modal>
  );
}

export default ContactUs;
