import React from 'react';
import cn from 'classnames';

type Props = {
  color?: string;
  className?: string;
  width?: number;
  height?: number;
};

function EyeIcon({ color = 'black', className, width = 50, height = 50 }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 50 50" fill="none">
      <path
        d="M6.62875 26.6833C8.81834 30.4438 14.9892 39.1875 24.9996 39.1875C35.0267 39.1875 41.1871 30.4396 43.3746 26.6833C43.6727 26.1727 43.8296 25.592 43.8292 25.0008C43.8288 24.4095 43.6712 23.829 43.3725 23.3188C41.185 19.5604 35.0183 10.8125 24.9996 10.8125C14.9808 10.8125 8.81625 19.5563 6.62875 23.3146C6.32957 23.8255 6.17188 24.4069 6.17188 24.999C6.17188 25.591 6.32957 26.1724 6.62875 26.6833Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M25 30.4687C26.4504 30.4687 27.8414 29.8926 28.867 28.867C29.8926 27.8414 30.4687 26.4504 30.4687 25C30.4687 23.5496 29.8926 22.1586 28.867 21.133C27.8414 20.1074 26.4504 19.5313 25 19.5312C23.5496 19.5313 22.1586 20.1074 21.133 21.133C20.1074 22.1586 19.5313 23.5496 19.5312 25C19.5313 26.4504 20.1074 27.8414 21.133 28.867C22.1586 29.8926 23.5496 30.4687 25 30.4687Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EyeIcon;
