import img from '../../../images/products/7.png';
export const PlanDPConstant = {
  name: 'Планирование',
  img: img,
  text: `Платформа INNERICA помогает создавать детальные графики и планы бурения. Вы можете разрабатывать сетевые квартальные графики, контролировать выполнение работ в режиме реального времени и оптимизировать процесс бурения`,
  list: [
    `Визуализация планов бурения`,
    `Интеграция с геологическими данными для повышения точности планирования`,
    `Возможность совместной работы команды в режиме реального времени`,
  ],
  effects: [
    `Улучшение контроля за выполнением работ`,
    `Снижение времени на планирование`,
    `Повышение точности и эффективности бурения`,
  ],
  items: [
    {
      Id: '01',
      Title: `График движения буровых блоков`,
      Text: `Планируйте работы с учетом графика движения буровых блоков.`,
    },
    {
      Id: '02',
      Title: `Сетевой квартальный график`,
      Text: `Создавайте детальные графики для эффективного управления проектами.`,
    },
    {
      Id: '03',
      Title: `Оперативный график`,
      Text: `Контролируйте выполнение работ в режиме реального времени.`,
    },
  ],
};
