import React from 'react';
import cn from 'classnames';

// import style from './RoundButton.module.css';

const getRotationAngle = (direction: string): number => {
  const angleMap: { [key: string]: number } = {
    right: 90,
    left: -90,
    down: 180,
    default: 0,
  };
  return angleMap[direction.toLowerCase()] || angleMap['default'];
};
type Props = {
  pos?: 'right' | 'left' | 'bottom' | 'up';
  color?: string;
  colorArrow?: string;
  className?: string;
  onClick?: () => void;
};

function RoundButton({
  onClick,
  className,
  color = '#838383',
  colorArrow = '#838383',
  pos = 'right',
}: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      transform={`rotate(${getRotationAngle(pos)})`}
      onClick={onClick}
      className={className}
      style={{ cursor: onClick ? 'pointer' : 'initial' }}
    >
      <circle cx="32" cy="32" r="31.5" stroke={color} />
      <path d="M40 35L32 27L24 35" stroke={colorArrow} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default RoundButton;
