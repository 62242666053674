import React, { useState, useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import cn from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

// import LoginButton from '../Buttons/LoginButton';
import { StoreContext } from '../../store';

import style from './Header2.module.css';
import HeaderItem1 from './HeaderItem2';

function HeaderBy2(): JSX.Element {
  const [isOpen, setOpen] = useState(false);
  const [bodyOffset, setBodyOffset] = useState(document.body.getBoundingClientRect());
  const [smallHeader, setSmallHeader] = useState(bodyOffset.top > 20);

  const listener = (): void => {
    setBodyOffset(document.body.getBoundingClientRect());
    setSmallHeader(-bodyOffset.top > 20);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return (): void => {
      window.removeEventListener('scroll', listener);
    };
  });

  function scrollToTop(): void {
    animateScroll.scrollToTop();
  }

  function hideMenu(): void {
    setOpen(false);
  }

  // const { contactUs } = React.useContext(StoreContext);
  React.useContext(StoreContext);

  function handleToggleClick(): void {
    setOpen(!isOpen);
  }

  const hostname = window.location.hostname;
  const isBY = hostname.endsWith('.by');
  const menuItems = [
    // { to: 'advantages', label: 'Преимущества' },
    // { to: 'product', label: 'Продукт' },
    // { to: 'clients', label: 'Клиенты', isHidden: isBY },
    // { to: 'calculator', label: 'Калькулятор', isHidden: isBY },
    // { to: 'equipments', label: 'Оборудование' },
    // { to: 'review', label: 'Отзывы', isHidden: isBY },
    { to: 'platform', label: 'Платформа', id: 1 },
    { to: 'product1', label: 'О продукте', id: 3 },
    { to: 'clients1', label: 'Клиенты', id: 7 },
    { to: 'equipments1', label: 'Оборудование', id: 8 },
    { to: 'review1', label: 'Отзывы', id: 9 },
  ];

  return (
    <header className={cn(style.header, smallHeader && style.small)}>
      <nav className={style.nav}>
        <button className={style.logo} onClick={scrollToTop} aria-label="logo" />
        <ul className={style.ulmain}>
          <OutsideClickHandler onOutsideClick={hideMenu}>
            <div className={cn(style.itemsContainer, isOpen && style.mobileMenu)}>
              {menuItems.map(item => {
                // if (item.isHidden) return;
                return (
                  <HeaderItem1
                    id={item.id}
                    key={item.to}
                    onClick={hideMenu}
                    to={item.to}
                    className={cn(style.item, isOpen && style.itemAppear)}
                  >
                    {item.label}
                  </HeaderItem1>
                );
              })}
            </div>
          </OutsideClickHandler>
          {/* <li className={style.login}>
            <LoginButton openSignUp={contactUs.open} />
          </li> */}
          <div className={style.contacts}>
            <div className={style.phone}>
              <a href="tel:+7(985)305-05-41">+7 (985) 305-05-41</a>
            </div>
            <div className={style.btnphone}>{/* <button onClick={contactUs.open}>перезвонить</button> */}</div>
          </div>
          {/* <div className={style.line} />
          <div className={style.contacts}>
            <li className={style.mail}>
              <a href="mailto:main.office@innerica.by">main.office@innerica.by</a>
            </li>
            <li className={style.phone}>
              <a href="tel:+375(29)970-76-06">+375 (29) 970-76-06</a>
            </li>
          </div> */}
        </ul>
        <button className={style.menu} onClick={handleToggleClick} aria-label="toggle" type="button">
          Меню
        </button>
      </nav>
    </header>
  );
}

export default HeaderBy2;
