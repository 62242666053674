import React, { forwardRef, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import Tag from '../UIkit/Tag';
import Clients1 from '../Clients1';
import { isElementInViewport } from '../../utils/isElementInViewport';

import style from './CustomerFocus.module.css';
import { CUSTOMERFOCUS } from './constants';
// import { FACILITIES } from './constants';
import TransitArrow from './../SVG/TransitArrow';

// interface Props {}
const CustomerFocus = forwardRef<HTMLDivElement>((props, ref) => {
  const isBY = window.location.hostname.endsWith('.by');
  const [offset, setOffset] = useState(0);
  function handleScroll() {
    console.log('handleScroll');
    const items = document.querySelectorAll(`.${style.item}`);
    items.forEach(item => {
      if (isElementInViewport(item as HTMLElement)) {
        item.classList.add(style.visible);
      }
    });

    // setOffset(prevOffset => prevOffset + 50);
    // const scrollY = window.scrollY;
    // const container = document.querySelector(`.${style.container}`);
    // if (container) {
    //   (container as HTMLElement).style.backgroundPosition = `center -${scrollY * 0.2}px`;
    // }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  //
  // useEffect(() => {
  //   const container = document.querySelector(`.${style.container}`);
  //   if (container) {
  //     (container as HTMLElement).style.backgroundPosition = `center -${offset}px`;
  //   }
  // }, [offset]);

  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<NodeListOf<Element>>();

  useEffect(() => {
    if (!containerRef.current) return;

    itemsRef.current = containerRef.current.querySelectorAll(`.${style.item}`);
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add(style.visible);
          }
        });
      },
      {
        root: document.querySelector('.your-scroll-container'),
        threshold: 0.1,
      },
    );

    itemsRef.current.forEach(item => observer.observe(item));

    return () => {
      itemsRef.current!.forEach(item => observer.unobserve(item));
    };
  }, []);
  return (
    <article ref={containerRef}>
      <div className={style.container} id="clients1">
        <div className={style.header}>
          <h2 className={style.h2}>Клиентоориентированность</h2>
          <Tag className={style.header__tag} title={'Мы предлагаем'} color="white" />
        </div>
        <div className={style.grid} data-scroll-speed="0.5">
          {CUSTOMERFOCUS.items.map(el => {
            if (!el.description) {
              return (
                <div
                  key={`CustomerFocus_${el.id}`}
                  className={cn(style.item, style.imgbox)}
                  data-scroll
                  data-scroll-class={style.visible}
                >
                  <img src={el.img} alt="" />
                </div>
              );
            }
            return (
              <div
                key={`CustomerFocus_${el.title}`}
                className={cn(style.card, style.item)}
                data-scroll
                data-scroll-class={style.visible}
              >
                <div className={style.cardHeader}>
                  <Tag as="h3" title={el.title} className={style.title} />
                  <TransitArrow className={style.arrow} opacity="0.1" />
                </div>
                <p className={style.description}>{el.description}</p>
                <div className={style.id}>{String(el.id).padStart(2, '0')}</div>
              </div>
            );
          })}
        </div>
      </div>
      {!isBY && <Clients1 />}
    </article>
  );
});
CustomerFocus.displayName = 'CustomerFocus';

export default CustomerFocus;
