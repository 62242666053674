import img from '../../../images/products/5.png';
export const CostsDPConstant = {
  name: 'Управление затратами на бурение (Drilling Cost) ',
  img: img,
  text: `Платформа INNERICA помогает точно планировать и контролировать затраты на бурение. Вы можете рассчитывать стоимость с учетом глубины, сложности скважины и других факторов`,
  list: [
    `Анализ фактических затрат позволяет оптимизировать бюджет и снизить расходы`,
    `Использование исторических данных для точного прогнозирования затрат`,
    `Интеграция с системами бухгалтерского учета для автоматического обновления данных`,
  ],
  effects: [
    `Снижение затрат за счет точного планирования`,
    `Улучшение контроля за бюджетом`,
    `Повышение прозрачности финансовых процессов`,
  ],
  items: [
    {
      Id: '01',
      Title: `Планирование с учетом опыта`,
      Text: `Используйте исторические данные для точного планирования затрат на бурение.`,
    },
    {
      Id: '02',
      Title: `Калькуляция стоимости`,
      Text: `Рассчитывайте стоимость бурения с учетом всех факторов, включая глубину и сложность скважины.`,
    },
    {
      Id: '03',
      Title: `Анализ затрат план/факт`,
      Text: `Сравнивайте плановые и фактические затраты, чтобы оптимизировать бюджет.`,
    },
  ],
};
