import img from '../../../images/products/4.png';
export const RaportDPConstant = {
  name: 'Суточный рапорт бурения (Daily Drilling Report)',
  img: img,
  text: `Суточный рапорт — важный инструмент для контроля процесса бурения. С платформой INNERICA вы можете подготовить рапорт всего за 15 минут. Все данные автоматически собираются и анализируются, что упрощает процесс и повышает точность`,
  list: [
    `Автоматическое формирование отчетов в соответствии с требования разных заказчиков (гибкая адаптация)`,
    `Возможность экспорта данных в форматы Excel, PDF и другие`,
    `Интеграция с корпоративными ERP системами для автоматического обновления данных`,
  ],
  effects: [
    `Снижение времени на подготовку рапортов и производственной отчетности`,
    `Улучшение контроля за выполнением задач`,
    `Повышение прозрачности процессов`,
  ],
  items: [
    {
      Id: '01',
      Title: `Подготовка за 15 минут`,
      Text: `Наш модуль позволяет быстро подготовить суточный рапорт, сокращая время на оформление документов.`,
    },
    {
      Id: '02',
      Title: `Корпоративная база данных`,
      Text: `Все данные автоматически сохраняются в единой системе, что упрощает доступ и анализ.`,
    },
    {
      Id: '03',
      Title: `Интеграция с 1С`,
      Text: `Автоматизируйте документооборот и учет, интегрируя платформу с системой 1С.`,
    },
  ],
};
